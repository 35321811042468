<template>
  <div class="process-order">
    <div class="loading" v-if="isLoading"></div>
    <template v-else>
      <button
        type="button"
        class="add-to-cart-btn"
        @click.prevent="(event) => processOrderAction(event)"
      >
        {{ processordertext ? processordertext : $t("labels.processOrder") }}
      </button>
      <span class="or-separator">{{ $t("labels.or") }}</span>
      <button
        type="button"
        class="skip-process-btn"
        @click.prevent="(event) => skipProcessAction(event)"
      >
        {{ $t("labels.skipProcess") }}
      </button>
    </template>
  </div>
</template>

<script>
import CartService from "@/services/cart";
import { useDraftStore } from "@/store/draft";
import ProductService from "@/services/product";
import { useOrderHistoryStore } from "@/store/orderHistory";
import Orion from "@/services/orion";
import { useCartStore } from "@/store/cart";
import Utils from "@/services/utils";

export default {
  name: "CheckoutButtonProcessOrder",
  props: {
    item: {
      type: Object,
    },
    type: {
      type: String,
      default: "crosssell",
    },
    processordertext: {
      type: String,
    },
  },
  computed: {
    isLoading() {
      return useDraftStore().isSendingDraftOrder;
    },
  },
  methods: {
    async processOrderAction(event) {
      if (this.item) {
        this.item.qtyIncr = 1;
        this.item.choice = ProductService.getDefaultChoice(this.item);
        CartService.addToCart(this.item);

        useDraftStore().sendingDraftOrder(true);
        const previousOrder =
          useOrderHistoryStore().orderHistory.slice(-1)[0].order;

        const newOrder = Object.assign({}, previousOrder);
        newOrder.previousExternalOrderId = previousOrder.externalOrderId;
        newOrder.externalOrderId = Utils.generateUID();
        useDraftStore().updateOrder(newOrder);

        const preparedOrder = Orion.prepareOrder(
          useCartStore().cart,
          newOrder,
          { orderType: this.type },
        );
        useDraftStore().updateOrder(preparedOrder);
        console.log("ProcessOrderPreparedOrder", preparedOrder);

        const minifiedCart = CartService.minifyCart();
        await Orion.sendOrder(
          minifiedCart,
          preparedOrder,
          window.globalConfig.domainId,
          this.type,
        );

        useOrderHistoryStore().addOrderToHistory(
          useCartStore().cart,
          preparedOrder,
          null
        );

        useDraftStore().sendingDraftOrder(false);
        useDraftStore().draftOrderSent(true);

        CartService.emptyCart();
      }
    },
    skipProcessAction(event) {
      CartService.addToCartLoading(true);

      useOrderHistoryStore().addStepToHistory();

      CartService.addToCartLoading(false);
    },
  },
};
</script>

<template>
  <template v-if="!loading">
    <div
      v-if="showBeforeText && extraProductBeforeText"
      v-html="renderHTML(extraProductBeforeText)"
      class="extra-product-before"
    ></div>
    <productlist
      categoryid="extraproducts"
      :mode="mode"
      :count="count"
      :showaddtocart="showaddtocart"
      :showprocessorder="showprocessorder"
      :processordertext="processOrderText"
      :postMountedProducts="extraProducts"
      :columnFormat="columnformat"
    />
    <div
      v-if="showAfterText && extraProductAfterText"
      v-html="renderHTML(extraProductAfterText)"
      class="extra-product-after"
    ></div>
  </template>
</template>

<script>
import productlist from "@/components/productlist/ProductList.vue";
import FetchFile from "@/services/fetchFile";
import ExtraSellService from "@/services/extraSell";
import { useCartStore } from "@/store/cart";
import Utils from "@/services/utils";

export default {
  name: "ProductExtraProducts",
  components: {
    productlist,
  },
  data() {
    return {
      cart: null,
      loading: true,
      extraProductReference: null,
      extraProducts: [],
    };
  },
  props: {
    mode: {
      type: String,
      default: "products-grid",
    },
    count: {
      type: Number,
      default: 3,
    },
    columnformat: {
      type: String,
      default: "4/8",
    },
    productReference: {
      // Object { productId: "", productDetailId: "", productFileName: "", step: "" }
      type: Object,
    },
    showaddtocart: {
      type: Boolean,
      default: true,
    },
    showprocessorder: {
      type: Boolean,
      default: false,
    },
    showBeforeText: {
      type: Boolean,
      default: false,
    },
    showAfterText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    lastCartUpdate() {
      return useCartStore().lastUpdate;
    },
    extraProductBeforeText() {
      return this.extraProducts?.length > 0 && this.extraProducts[0].argument1
        ? this.extraProducts[0].argument1
        : null;
    },
    processOrderText() {
      return this.extraProducts?.length > 0 && this.extraProducts[0].argument2
        ? Utils.decodeEntities(
            decodeURIComponent(this.extraProducts[0].argument2),
          )
        : null;
    },
    extraProductAfterText() {
      return this.extraProducts?.length > 0 && this.extraProducts[0].argument3
        ? this.extraProducts[0].argument3
        : null;
    },
  },
  mounted() {
    this.cart = this.getCart();
    this.updateExtraProducts();
  },
  watch: {
    lastCartUpdate: {
      handler(newValue, oldValue) {
        if (!window?.globalConfig?.domainId) {
          return;
        }
        this.cart = this.getCart();
      },
    },
    productReference: {
      handler(newValue, oldValue) {
        this.extraProducts = [];
        this.updateExtraProducts();
      },
    },
  },
  methods: {
    getCart() {
      return useCartStore().cart;
    },
    findExtraProductReference() {
      if (this.productReference) {
        return {
          extrasells: [this.productReference],
        };
      }

      return window.productDetails;
    },
    updateExtraProducts() {
      this.loading = true;

      if (!window.productDetails) {
        return;
      }

      this.extraProductReference = this.findExtraProductReference();

      if (!this.extraProductReference) {
        return;
      }

      let totalExtraProducts = 0;
      for (const extra of this.extraProductReference.extrasells) {
        this.loading = false;
        document.addEventListener(
          "change-information-extra-product",
          (event) => {
            this.extraProducts.forEach((product) => {
              if (product.code === event.detail.productCode) {
                switch (event.detail.typeChange) {
                  case "images-url":
                    product.images.forEach((image) => {
                      image.src = image.src.replace(
                        event.detail.replaceFrom,
                        event.detail.replaceTo,
                      );
                    });
                    break;
                  default:
                    break;
                }
              }
            });
          },
        );
        const fileName = extra.productFileName
          ? extra.productFileName
          : `${extra.productId}.json`;
        new FetchFile().fetch(`/data/products/${fileName}`, ({ data }) => {
          this.extraProducts = ExtraSellService.getExtraProducts(
            data,
            this.extraProductReference.extrasells,
            this.cart.items,
            this.extraProducts,
            this.mode,
          );
          totalExtraProducts++;
          if (
            totalExtraProducts === this.extraProductReference.extrasells.length
          ) {
            // Due to time to rendering
            setTimeout(() => {
              document.dispatchEvent(new Event("extra-products-loaded"));
            }, 1);
          }
        });
      }
    },
  },
};
</script>

import axios from "axios";

export default class Palyance {
  static getAspspEligibility(walletIdentifier, paymentType, merchantIdentifier) {
    return new Promise((resolve, reject) => {
      try {
        const url = `${process.env.VUE_APP_PALYANCE_API_BASE_URL}/eligibility/aspsp?walletIdentifier=${encodeURIComponent(walletIdentifier)}&paymentType=${encodeURIComponent(paymentType)}&merchantIdentifier=${encodeURIComponent(merchantIdentifier)}`;

        axios.get(url).then(({data}) => {
          if (!data) {
            throw new Error('No data returned from palyance');
          }

          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  static getOgoneStatus(authorizationId) {
    return new Promise((resolve, reject) => {
      try {
        const url = `${process.env.VUE_APP_PALYANCE_API_BASE_URL}/ogone/waiting?authorizationId=${encodeURIComponent(authorizationId)}`;

        axios.get(url).then(({data}) => {
          if (!data || !data.status) {
            return reject(data);
          }

          resolve(data);
        }, error => {
          reject(error);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  static hasClientValidatedDoubleOptin(orderId) {
    return new Promise((resolve, reject) => {
      try {
        const url = `${process.env.VUE_APP_PALYANCE_API_BASE_URL}/aspsp/double-optin?orderId=${encodeURIComponent(orderId)}`;

        axios.get(url).then(({data}) => {
          if (!data) {
            throw new Error('No data returned from palyance');
          }

          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  static postToken(orderId, token) {
    return new Promise((resolve, reject) => {
      try {
        const url = `${process.env.VUE_APP_PALYANCE_API_BASE_URL}/aspsp/double-optin-code`;

        axios.post(url, {
          orderId,
          digitConfirmation: token,
          signedAt: Date.now()
        }).then(({data}) => {
          if (!data) {
            throw new Error('No data returned from palyance');
          }

          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}
import axios from 'axios';

let instance;

export default class FetchFile {
  constructor() {
    if (instance) {
      return instance;
    }

    this.data = {};

    instance = this;
    return instance;
  }

  fetch(path, callback) {
    if (this.data && this.data[path]) {
      return this.data[path].then(result => callback(result));
    }

    this.data[path] = axios.get(path);

    return this.data[path].then(result => callback(result));
  }
}
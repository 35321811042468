<template>
    <ul v-if="cart?.items?.length" class="cart-totals">
        <li class="subtotal">
            <span class="label">{{ $t('labels.subTotal') }} :</span>
            <span class="subtotal">{{ subtotal }}</span>
            <span class="currency">&euro;</span>
        </li>
        <li class="shipping-price-total">
            <span class="label">{{ $t('labels.shippingFees') }} :</span>
            <span class="amount">{{ shippingPrice }}</span>
            <span class="currency">€</span>
        </li>
        <li class="coupons" v-for="coupon in cart.coupons">
            <span class="label">{{ $t('labels.coupon') }} :</span>
            <span class="subtotal"><strong class="text-success">{{ coupon.code }}</strong></span>
            <span v-if="coupon.discountAmount && coupon.isPercentage" class="discount-details">
                (-{{ coupon.discountAmount }}%)
            </span>
            <button type="button" name="deleteCoupon" @click.prevent="deleteCouponAction(coupon)"
                    class="delete-coupon-btn">{{ $t('labels.deleteCoupon') }}
            </button>
            <span v-if="coupon.discountedAmount" class="separator"> :</span>
            <span v-if="coupon.discountedAmount" class="amount">-{{ formatPrice(coupon.discountedAmount) }}</span>
            <span v-if="coupon.discountedAmount" class="currency">€</span>
        </li>
        <li class="total">
            <span class="label">{{ $t('labels.total') }} :</span>
            <span class="subtotal">{{ total }}</span>
            <span class="currency">&euro;</span>
        </li>
    </ul>
</template>
<script>
import {useCartStore} from "@/store/cart";
import CouponService from "@/services/coupon";
import Price from "@/services/price";

export default {
    name: 'CartTotals',
    data() {
        return {
            cart: null,
            subtotal: 0,
            shippingPrice: 0,
            total: 0
        }
    },
    computed: {
        lastCartUpdate() {
            return useCartStore().lastUpdate;
        },
    },
    mounted() {
        this.cart = this.getCart();
        this.subtotal = this.formatPrice(this.cart.defaultSubtotal ? this.cart.defaultSubtotal : this.cart.subtotal);
        this.shippingPrice = this.formatPrice(this.cart.shippingPrice);
        this.total = this.formatPrice(this.cart.total);
    },
    methods: {
        getCart() {
            return useCartStore().cart;
        },
        deleteCouponAction(coupon) {
            CouponService.deleteCoupon(coupon.code);
        },
        formatPrice(price) {
            return Price.format(price);
        }
    },
    watch: {
        lastCartUpdate: {
            handler(newValue, oldValue) {
                if (!window?.globalConfig?.domainId) {
                    return;
                }
                this.cart = this.getCart();
            }
        },
      cart: {
        handler(newValue, oldValue) {
          if (!window?.globalConfig?.domainId) {
            return;
          }

          this.subtotal = this.formatPrice(this.cart.defaultSubtotal ? this.cart.defaultSubtotal : this.cart.subtotal);
          this.shippingPrice = this.formatPrice(this.cart.shippingPrice);
          this.total = this.formatPrice(this.cart.total);
        },
        deep: true
      }
    }
}
</script>
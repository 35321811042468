import axios from "axios";


export default class PaginationService {
  static async getPage(slug, pageId, paginationMode) {
    if (paginationMode === 'auto-lazy') {
      return await getStaticJson(slug, pageId);
    }
  }
}

const getStaticJson = async (slug, pageId) => {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/data/categories/${slug}/page_${pageId}.json`,
    headers: { }
  };

  return await axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error('Error fetching page');
    });
};

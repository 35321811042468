<template>
  <span class="upsell-description" v-if="description" v-html="renderHTML(description)"></span>
</template>

<script>
export default {
    name: 'ChoiceUpsellDescription',
    props: {
        choice: {
            type: Object
        }
    },
    computed: {
        description() {
            // This is computed because choice may change after init
            return this?.choice?.description;
        }
    }
}
</script>
<template>
    <!-- TODO-->
</template>

<script>

import AddToCart from '@/components/AddToCart.vue';
import Utils from "@/services/utils";

export default {
    name: 'ProductListSliderCart',
    props: {
        products: {
            type: Array,
            default: () => []
        },
        categoryid: {
            type: String,
            default: ''
        },
        showaddtocart: {
            type: Boolean,
            default: false
        },
        addToCartLabel: {
            type: String
        },
        showprocessorder: {
          type: Boolean,
          default: false
        },
        processordertext: {
          type: String
        },
        getDefaultChoice: {
            type: Function,
            required: true
        },
        fillProductWithChoice: {
            type: Function,
            required: true
        }
    },
    components: {
        AddToCart
    },
    methods: {
        displayDuration(duration, startingPrice = false) {
            return Utils.displayDuration(duration, this.$i18n.locale, startingPrice);
        },
    }
}
</script>

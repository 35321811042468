<template>
  {{ $t("labels.save") }}
  <span class="amount">{{ formatPrice(diff) }}</span>
  <span class="currency">&euro;</span>
</template>

<script>
import Price from "@/services/price";

export default {
  name: "CartTableUpsellDiff",
  props: {
    cartItemChoice: {
      type: Object,
    },
    upsellChoice: {
      type: Object,
    },
  },
  computed: {
    diff() {
      return (
        this.cartItemChoice.unitPrice / this.cartItemChoice.numberOfUnit -
        this.upsellChoice.unitPrice / this.upsellChoice.numberOfUnit
      ); // TODO
    },
  },
  methods: {
    formatPrice(price) {
      const formattedPrice = Price.format(price);
      return formattedPrice.replace(".00", "");
    },
  },
};
</script>

<template>
  <div v-for="(cartItem, index) in cart?.items" class="choice-item">
    <div class="choice-item-details">
      <div class="choice-left-col">
        <img :src="cartItem.images.find((img) => img.type === 'main').src" />
      </div>
      <div class="choice-right-col">
        <div class="title-price">
          <div class="product-name">
            <ChoiceVanguardLabel :choice="cartItem.choice" />
          </div>
          <div class="free-label">
            <ChoiceFreeLabel :choice="cartItem.choice" />
          </div>
        </div>
        <div>
          <span class="small-text"
            ><ChoiceVanguardDescription :choice="cartItem.choice"
          /></span>
          <div class="quantity-frequency">
            <div
              class="btn btn-light qty-btn"
              :data-quantity-select="cartItem.choice.id"
            >
              <div class="qty-btn-text">
                <label class="qty-btn-text-label">{{ $t("labels.qty") }}</label>
                <select
                  v-model="cartItem.qty"
                  v-on:input="validateQty(cartItem, $event)"
                  v-show="cartItem.choice?.isQuantityEditable ?? true"
                  class="quantity-select"
                >
                  <option
                    v-for="n in 15"
                    :value="n"
                    :selected="cartItem.qty === n"
                  >
                    {{ n }}
                  </option>
                </select>
                <span class="qty-btn-text-value">{{ cartItem.qty }}</span>
              </div>
            </div>
            <button
                v-show="!cart.hideDelete"
                type="button"
                name="deleteItem"
                @click.prevent="deleteItemAction(cartItem)"
                class="delete-item-btn"
                :title="$t('labels.deleteItem')"
            >
              <i class="fa-solid fa-trash-alt"></i>
            </button>
            <span class="small-text">
              <ChoicePrice :choice="cartItem.choice" />
            </span>
          </div>
        </div>
      </div>


    </div>

    <CartTableUpsellList :cartItem="cartItem" />
  </div>
</template>

<script>
import CartTableUpsellList from "@/components/cart/table/UpsellList.vue";

import ChoiceFreeLabel from "@/components/choice/FreeLabel.vue";
import ChoiceVanguardDescription from "@/components/choice/VanguardDescription.vue";
import ChoiceVanguardLabel from "@/components/choice/VanguardLabel.vue";
import { useCartStore } from "@/store/cart";
import ChoicePrice from "@/components/choice/Price.vue";
import CartService from "@/services/cart";

export default {
  name: "CartTableChoiceList",
  components: {
    ChoicePrice,
    ChoiceFreeLabel,
    ChoiceVanguardDescription,
    ChoiceVanguardLabel,
    CartTableUpsellList,
  },
  data() {
    return {
      cart: null,
      lastCheckedCartSignature: null,
    };
  },
  computed: {
    lastCartUpdate() {
      return useCartStore().lastUpdate;
    },
  },
  watch: {
    lastCartUpdate: {
      handler(newValue, oldValue) {
        if (!window?.globalConfig?.domainId) {
          return;
        }
        this.cart = this.getCart();
      },
    },
    cart: {
      handler(newValue, oldValue) {
        if (!window?.globalConfig?.domainId) {
          return;
        }
        if (this.lastCheckedCartSignature !== useCartStore().calcSignature()) {
          this.lastCheckedCartSignature = useCartStore().calcSignature();
          this.$nextTick(() => {
            CartService.updateCart();
          });
        }
      },
      deep: true,
    }
  },
  mounted() {
    this.cart = this.getCart();
  },
  methods: {
    getCart() {
      return useCartStore().cart;
    },
    validateQty(item, event) {
      const value = event.target.value;
      console.log("validateQty", event.target.value);

      if (isNaN(value) || value < 0) {
        item.qty = 1;
        return;
      }

      item.qty = Math.floor(value);
      //useCartStore().setLastUpdate();
    },
    deleteItemAction(item) {
      CartService.deleteItem(item);
    },
  }
};
</script>

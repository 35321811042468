<template>
    <div v-if="longDescription" v-html="renderHTML(longDescription)"></div>
</template>

<script>
export default {
    name: 'CategoryLongDescription',
    props: [],
    data() {
        return {
            longDescription: '',
        }
    },
    mounted() {
        this.longDescription = window.category.longDescription;
    }
}
</script>
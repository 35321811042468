<template>
    <a href="#" class="action showcart" @click.prevent="showMinicartAction">
        <span v-if="cart?.items?.length > 0" class="counter qty" :data-quantities="quantities()"
              :data-products-count="cart.items.length">
            <span class="counter-number">{{ cart.items.length }}</span>
        </span>
    </a>
</template>

<script>
import {useCartStore} from '@/store/cart.js';

export default {
    name: 'CartMinicart',
    data() {
        return {
            cart: null,
        };
    },
    computed: {
        lastCartUpdate() {
            return useCartStore().lastUpdate;
        }
    },
    watch: {
        lastCartUpdate: {
            handler(newValue, oldValue) {
                if (!window?.globalConfig?.domainId) {
                    return;
                }
                this.cart = this.getCart();
            },
        },
    },
    mounted() {
        this.cart = this.getCart();
        document.addEventListener('click', (event) => {
            if (event.target.classList.contains('mini-cart-close')) {
                document.body.classList.remove('minicart-active');
            }
            if (!event.target.closest('.minicart') &&
                !event.target.classList.contains('add-to-cart-btn') &&
                (!event.target.classList.contains('delete-item-btn') || this.cart.items.length === 0)
            ) {
                document.body.classList.remove('minicart-active');
            }
        });
    },
    methods: {
        getCart() {
            return useCartStore().cart;
        },
        showMinicartAction(event) {
            document.body.classList.toggle('minicart-active');
        },
        quantities() {
            let quantities = 0;
            for (const item of this.cart.items) {
                quantities += item.qty;
            }
            return quantities;
        }
    }
}
</script>

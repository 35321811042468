import { useDraftStore } from "@/store/draft";
import { constants } from "@/constants";

export default class PaymentMethodService {
  static getDefaultPaymentMethod() {
    const isSepaAvailable =
      !window.globalConfig.paymentMethodsAvailable ||
      !window.globalConfig.paymentMethodsAvailable.includes(",") ||
      window.globalConfig.paymentMethodsAvailable.split(",").includes("sepa");
    const isBankCardAvailable =
      !window.globalConfig.paymentMethodsAvailable ||
      !window.globalConfig.paymentMethodsAvailable.includes(",") ||
      window.globalConfig.paymentMethodsAvailable
        .split(",")
        .includes("bank_card");

    if (!isSepaAvailable && !isBankCardAvailable) {
      return null;
    }

    if (useDraftStore().selectedPaymentMethod) {
      return useDraftStore().selectedPaymentMethod;
    }

    if (isSepaAvailable) {
      return constants.PAYMENT_METHOD_SEPA;
    } else if (isBankCardAvailable) {
      return constants.PAYMENT_METHOD_BANK_CARD;
    }
  }

  static findChoiceMatchingPaymentMethod(choiceId, paymentMethodToMatch) {
    let matchingChoice = null;
    const paymentMethodChoices = window.productDetails.paymentMethodChoices;

    for (const paymentMethodChoice of paymentMethodChoices) {
      for (const paymentMethod of Object.keys(paymentMethodChoice)) {
        if (paymentMethod === paymentMethodToMatch) {
          continue;
        }
        if (paymentMethodChoice[paymentMethod] === choiceId) {
          const matchingChoiceId = paymentMethodChoice[paymentMethodToMatch];
          matchingChoice = window.productDetails.choices.find(
            (choice) => choice.id === matchingChoiceId,
          );
        }
      }
    }

    return matchingChoice;
  }
}

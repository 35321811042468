<template>
    <div v-if="debug" class="alert alert-danger alert-top text-center">{{testLevel}}</div>
</template>

<script>
import {useTrackingStore} from "@/store/tracking";
import Logger from "@/services/logger";

export default {
    name: 'Debug',
    data() {
        return {
            debug: false,
            testLevel: null
        }
    },
    mounted() {
        //Logger.log('test debug');
        this.checkTestLevel();
    },
    methods: {
        checkTestLevel() {
            this.testLevel = useTrackingStore().urlParams.testLevel;

            this.debug = !!this.testLevel;
        }
    }
}
</script>
<template>
    <span class="title" v-if="name" v-html="renderHTML(name)"></span>
    <meta itemprop="headline" v-if="name" :content="renderHTML(name)"/>
</template>

<script>
export default {
    name: 'ProductName',
    props: {
        product: {
            type: Object
        }
    },
    data() {
        return {
            name: '',
        }
    },
    mounted() {
        this.name = this.product?.name ||  window.productDetails.name;
    }
}
</script>
<template>
    <template v-if="mode === 'grid'">
        <div class="row justify-content-center" v-if="subcategories?.length">
            <div class="subcategory-item col-lg-3 col-md-4 col-sm-6" v-for="subcategory in subcategories" :key="subcategory.id">
                <div class="subcategory-item-info card h-100" @click="redirectToSubcategory(subcategory.url)">
                    <div class="subcategory-item-photo">
                        <img class="img-fluid subcategory-image"
                             :src="subcategory.image"
                             :alt="subcategory.label">
                    </div>
                    <div class="subcategory-item-details card-body">
                        <h3 class="subcategory-item-name">
                            {{ subcategory.label }}
                        </h3>
                        <span class="subcategory-short-description" v-html="subcategory.longName"></span>
                    </div>
                    <div class="card-footer subcategory-item-details-button">
                        <div class="access-category">
                            <button type="button" class="access-category-btn">
                                {{ buttonlabel ? buttonlabel : $t("labels.accessCategory") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <ul class="subcategories" v-if="subcategories?.length">
            <li v-for="subcategory in subcategories" :key="subcategory.id">
                <a :href="subcategory.url">
                    <span>{{ subcategory.label }}</span>
                </a>
            </li>
        </ul>
    </template>
</template>

<script>
export default {
    name: "CategorySubCategories",
    props: {
        mode: {
            type: String,
            default: "list",
        },
        buttonlabel: {
            type: String
        },
    },
    data() {
        return {
            subcategories: [],
        };
    },
    mounted() {
        this.getSubcategories();
    },
    methods: {
        getSubcategories() {
            const currentCategoryId = window.category.id;
            const subCategories = window.menu.items.find(menuItem => menuItem.id === currentCategoryId);
            if (subCategories) {
                this.subcategories = subCategories.items;
            }
        },
        redirectToSubcategory(url) {
            window.location.href = url;
        }
    },
};
</script>
export default class Product {
  static getDefaultChoice(product) {
    const defaultChoice = product.choices.find(choice => choice.default && choice.show);
    return defaultChoice ? defaultChoice : product.choices.find(choice => choice.show);
  }

  static mappingProduct(product) {
    const keys = Object.keys(product);
    const imageKeys = keys.filter(key => key.toLowerCase().includes('image') && key !== 'mainImage');
    product.images = [];
    imageKeys.forEach(imageKey => {
      if (imageKey === product.mainImage.toLowerCase()) {
        product.images.push({
          type: 'main',
          src: product[imageKey]
        })
      } else if (imageKey === 'featuredImage') {
        product.images.push({
          type: 'featuredImage',
          src: product[imageKey]
        });

      } else {
        product.images.push({
          type: 'secondary',
          src: product[imageKey]
        });
      }
    });
    const choiceKeys = keys.filter(key => key.toLowerCase().startsWith('choice'));
    product.choice = {
      default: true,
      show: true
    };
    choiceKeys.forEach(choiceKey => {
      const newKey = choiceKey.replace(/^choice/, '');
      const formattedKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
      if (choiceKey.includes('Price')) {
        product[choiceKey] = product[choiceKey] * 100;
      }
      product.choice[formattedKey] = product[choiceKey];
    });
    product.choice.unitPrice = product.choice.defaultPrice;
    if (product.choice.startingPrice) {
      product.choice.unitPrice = product.choice.startingPrice;
    }
    product.choices = [product.choice];
    return product;
  }
}
export default class AlreadyOrderedProducts {
    static name = 'shop-alreadyOrderedProducts'
    static getAlreadyOrderedProducts() {
        try {
            const alreadyOrderedProducts = JSON.parse(localStorage.getItem(this.name))
            if (!alreadyOrderedProducts) return null
            if (alreadyOrderedProducts.expirationDate < Date.now()) {
                localStorage.removeItem(this.name)
                return null
            }
            return alreadyOrderedProducts.productsSalesforceId
        } catch (e) {
            return null
        }
    }
    static setAlreadyOrderedProducts(productsSalesforceId) {
        const oneDay = 24 * 60 * 60 * 1000;
        const expirationDate = Date.now() + oneDay;
        const formatLocalStorage = {
            expirationDate: expirationDate,
            productsSalesforceId: productsSalesforceId
        }
        localStorage.setItem(this.name, JSON.stringify(formatLocalStorage));
    }

    static updateAfterPayment() {
        try {
            const existingAlreadyOrderedProducts = this.getAlreadyOrderedProducts() || []
            const orderHistory = JSON.parse(localStorage.getItem('shop-order-history'))

            for (const orderHistoryElement of orderHistory) {
                if(!orderHistoryElement?.cart?.items?.length) continue;

                const productsSalesforceId = orderHistoryElement?.cart?.items.map(product => product.salesforceId)
                for (const productSalesforceId of productsSalesforceId) {
                    if (!existingAlreadyOrderedProducts.includes(productSalesforceId)) {
                        existingAlreadyOrderedProducts.push(productSalesforceId)
                    }
                }
            }

            this.setAlreadyOrderedProducts(existingAlreadyOrderedProducts)
        } catch (e) {
            console.error('Error while updating already ordered products', e)
        }
    }
}
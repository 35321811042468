<template>
    <div class="choice-selector-wrapper">
        <div class="choice-selector">
            <template v-for="element in elementsVariation">
                <div :id="`${element}-${index}`" :class="`variation ${element}`" v-if="!keyIgnore.includes(element)">
                    <span class="element-title" v-html="renderHTML(config[element].label)"></span>
                    <template v-for="variation in variations[element]">
                        <label :class="`variation-container${disabledInputRadio(element, variation) ? ' input-disabled' : ''}`" :for="(`${element}-${variation}-${index}`).replace(/ /g, '-').replace(/\./g, '').toLowerCase()">
                            <span class="variation-name">{{ variation }}</span>
                            <input :id="(`${element}-${variation}-${index}`).replace(/ /g, '-').replace(/\./g, '').toLowerCase()" type="radio" :disabled="disabledInputRadio(element, variation)" :name="`${element}-${index}`" :checked="values[element] === variation" :value="variation" v-model="values[element]">
                            <span :class="`checkmark ${typeof variation === 'string' ? variation.replace(/ /g, '-').replace(/\./g, '').toLowerCase() : variation}`"></span>
                        </label>
                    </template>
                </div>
            </template>
        </div>
        <div class="choice-selected" v-if="choiceSelected">
            <div class="choiceSelectedName">
                <span class="labelChoiceName">{{ $t('labels.choiceName')}}</span>
                <span class="choiceName">{{ choiceName }}</span>
            </div>
            <div class="choiceSelectedPrice">
                <span class="labelChoicePrice">{{ $t('labels.choicePrice')}}</span>
                <template v-if="!isLoading">
                    <span :class="`${choicePriceWithCoupon ? 'choiceOldPriceWrapper' : 'choicePriceWrapper'}`">
                        <span class="choicePrice">{{ choicePrice }}</span>
                        <span class="currency">&euro;</span>
                    </span>
                    <span v-if="choicePriceWithCoupon" class="choiceNewPriceWrapper">
                        <span class="choicePrice">{{ choicePriceWithCoupon }}</span>
                        <span class="currency">&euro;</span>
                        <span class="fromCouponWrapper">
                            <span class="fromCouponText">{{ $t('labels.fromCoupon') }}</span>
                            <span :class="`fromCouponName ${coupon.toLowerCase()}`">{{coupon}}</span>
                        </span>
                    </span>
                </template>
                <template v-else>
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </template>
                <span class="alternative-payment-information" v-if="paymentMethodAlt">
                    <span class="alternative-payment-information-label-then">{{ $t('labels.then') }}</span>
                    <span class="alternative-payment-information-duration">{{ choiceMaxTermNumber - 1 }}</span>
                    x
                    <span class="alternative-payment-information-price">{{ choicePriceAlt }}</span>
                    <span class="alternative-payment-information-currency">&euro;</span>
                </span>
                <div class="payment-method-container" v-if="config.payment?.externalId_alt">
                    <span class="payment-method-label" v-html="renderHTML(paymentMethodLabel)"></span>
                    <div class="d-flex flex-column flex-xl-row">
                        <label :class="`payment-method-classic-container ${config.payment.default === 'externalId_alt' ? 'order-2' : 'order-1'}`">
                            <span class="payment-method-classic-label" v-html="renderHTML(config.payment.externalId)"></span>
                            <input id="payment-method-classic" type="radio" :checked="!paymentMethodAlt" :value="false" v-model="paymentMethodAlt">
                            <span class="checkmark payment-method-classic"></span>
                        </label>
                        <label :class="`payment-method-alt-container ${config.payment.default === 'externalId_alt' ? 'order-1' : 'order-2'}`">
                            <span class="payment-method-alt-label" v-html="renderHTML(config.payment.externalId_alt)"></span>
                            <input id="payment-method-alt" type="radio" :checked="paymentMethodAlt" :value="true" v-model="paymentMethodAlt">
                            <span class="checkmark payment-method-alt"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {useDraftStore} from "@/store/draft";
import {useTrackingStore} from "@/store/tracking";
import Price from "@/services/price";
import CouponService from "@/services/coupon";
import CartService from "@/services/cart";

export default {
    name: 'ProductDirectBuy',
    props: {
        index: {
            type: Number,
            default: 0
        },
        isFromList: {
            type: Boolean,
            default: false
        },
        chooseChoiceSelected: {
            type: Function,
            default: () => {}
        },
        product: {
            type: Object
        }
    },
    data() {
        return {
            keyIgnore: ['externalId', 'externalId_alt', 'tooltip', 'tooltip_alt'],
            choices: [],
            choicestVariation: [],
            config: {},
            elementsVariation: [],
            paymentMethodAlt: false,
            variations: {},
            values: {},
            couponInformation: null,
            isLoading: false
        }
    },
    computed: {
        choiceSelected() {
            return this.paymentMethodAlt ? this.choices.find(choice => choice.id === this.choiceVariant.externalId_alt) : this.choices.find(choice => choice.id === this.choiceVariant.externalId);
        },
        choiceName() {
            return this.choiceSelected.name;
        },
        choicePrice() {
            return this.paymentMethodAlt ? Price.format(this.choiceSelected.startingPrice) : Price.format(this.choiceSelected.defaultPrice);
        },
        choicePriceWithCoupon() {
            return this.couponInformation ? Price.format((this.choicePrice.replaceAll(" ", "") * 100) - this.couponInformation.discountedAmount) : null;
        },
        choicePriceAlt() {
            return Price.format(this.choiceSelected.defaultPrice);
        },
        choiceMaxTermNumber() {
            return this.choiceSelected.maxTermNumber;
        },
        choiceVariant() {
            return this.choicestVariation.find(variation => {
                return Object.keys(this.values).every(key => {
                    return this.keyIgnore.includes(key) ? true : variation[key] === this.values[key];
                });
            });
        },
        coupon() {
            return useTrackingStore().urlParams.coupon;
        },
        paymentMethodLabel() {
            let label = this.config.payment?.label;
            if (!this.paymentMethodAlt && this.choiceVariant.tooltip) {
                label += this.generateTooltip(this.choiceVariant.tooltip);
            } else if (this.paymentMethodAlt && this.choiceVariant.tooltip_alt) {
                label += this.generateTooltip(this.choiceVariant.tooltip_alt);
            }
            console.log(label)
            return label;
        },
        storeSelectedChoice() {
            return !this.isFromList ? useDraftStore().selectedChoice : null;
        },
        virtualCart() {
            const product = this.product || window.productDetails;
            return CartService.generateVirtualMinifyCart(product, this.choiceSelected, this.coupon);
        }
    },
    mounted() {
        try {
            const product = this.product || window.productDetails;
            this.choices = product.choices;
            const productVariation = JSON.parse(product.productVariation);
            this.choicestVariation = productVariation.choices;
            this.config = productVariation.config;
            if (this.config.payment?.default === 'externalId_alt') {
                this.paymentMethodAlt = true;
            }
            this.choicestVariation.forEach(variation => {
                const variationElements = Object.keys(variation);
                variationElements.forEach(element => {
                    if (!this.variations[element]) {
                        this.elementsVariation.push(element);
                        this.variations[element] = [];
                        this.values[element] = variation[element];
                    }
                    if (!this.variations[element].includes(variation[element])) {
                        this.variations[element].push(variation[element]);
                    }
                });
            })
            if (this.coupon) {
                this.isLoading = true;
                this.getPriceWithCoupon();
            }
        } catch (error) {
            console.error(error);
            this.choicestVariation = [];
            this.config = {};
            this.elementsVariation = [];
            this.variations = {}
        }
    },
    methods: {
        disabledInputRadio(element, value) {
            const fieldCanBeDisabled = this.config[element].fieldCanBeDisabled;
            if (!fieldCanBeDisabled) {
                return false;
            }
            const choices = this.choicestVariation.filter(variation => {
                return Object.keys(this.values).every(key => {
                    return key === 'externalId' || key === element ? true : variation[key] === this.values[key];
                });
            });
            for (const [index, choice] of choices.entries()) {
                if (!choice.externalId && choice[element] === value) {
                    if (this.choiceVariant === choice) {
                        const choiceSelected = index === 0 ? choices[1] : choices[0];
                        this.values = {...choiceSelected};
                    }
                    return true;
                }
            }
            return false;
        },
        changeChoice() {
            const newVal = this.choiceSelected ? this.choiceSelected.id : null;
            if (this.storeSelectedChoice !== newVal || this.isFromList) {
                !this.isFromList ? useDraftStore().selectChoice(newVal) : this.chooseChoiceSelected(newVal, this.index);
                if (this.coupon) {
                    this.isLoading = true;
                    this.getPriceWithCoupon();
                }
            }
        },
        generateTooltip(infoTooltip) {
            return ` <span class="tooltip-payment-method-label btn"><i class="bi bi-info-circle"></i><span class="tooltip-payment-method-label-text">${infoTooltip}</span></span>`;
        },
        getPriceWithCoupon() {
            CouponService.checkCoupons(this.virtualCart, false, true).then(response => {
                const coupon = response.data.cart.coupons[this.coupon];
                if (coupon.isValid) {
                    this.couponInformation = response.data.cart.coupons[this.coupon]
                } else {
                    this.couponInformation = null
                }
                this.isLoading = false;
            }).catch(() => {
                console.log('The coupon is not for this product')
                this.isLoading = false;
            })
        },
    },
    watch: {
        values: {
            handler: function () {
                this.changeChoice();
            },
            deep: true
        },
        paymentMethodAlt: {
            handler: function () {
                this.changeChoice();
            }
        }
    }
}
</script>
/**
 * Find the country to block based on billing or shipping zip code and country code.
 *
 * @param {string} zipCode - The billing or shipping postal code.
 * @param {string} countryCode - The billing or shipping country code.
 * @param {object} paysValidate - The object containing mappings of postal codes to block.
 * @returns {string|null} The country code to block, or null if no blocking is required.
 */
const findCountryToBlock = (zipCode, countryCode, paysValidate) => {

    // Determine the postal code prefix based on the country
    const zipPrefix = countryCode === 'ESP' ? zipCode?.substring(0, 2) : zipCode?.substring(0, 3);

    const countryMapping = paysValidate?.[countryCode];

    let matchedCountry = Object.entries(countryMapping || {})
        .find(([zipCodePattern]) => zipCodePattern.startsWith(zipPrefix));

    return matchedCountry ? window.globalConfig.countriesExcluded.includes(matchedCountry[1]) ? 'blocked' : matchedCountry[1] : null;
};

export { findCountryToBlock };


<template>
    <ul v-if="this.categories.length" class="category-list">
        <li v-for="category in categories" class="category-item">
            <a :href="category.url">{{ category.label }}</a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'ProductCategories',
    data() {
        return {
            categories: []
        };
    },
    mounted() {
        this.categories = this.getCategories();
    },
    methods: {
        getCategories() {
            if (!window.productDetails || window.productDetails.length === 0) {
                return [];
            }

            return window.productDetails.categories;
        }
    }
};
</script>
<template>{{ longName }}</template>

<script>
export default {
    name: 'CategoryLongName',
    props: [],
    data() {
        return {
            longName: '',
        }
    },
    mounted() {
        this.longName = window.category.longName;
    }
}
</script>
export default class Shipping {
  static getShippingPriceTotal(items, countryCode) {
    let shippingPrice = 0;

    // Calculate default shipping price
    for (const cartItem of items) {
      if (cartItem.shippingPrice > shippingPrice) {
        shippingPrice = cartItem.shippingPrice;
      }
    }

    // Apply shipping rules
    if (items.length && window.shippingConfig) {

      if (window.shippingConfig && window.shippingConfig.freeShippingFromNumberOfProducts &&
        window.shippingConfig.freeShippingFromNumberOfProducts > -1 &&
        this.getQuantities(items) >= window.shippingConfig.freeShippingFromNumberOfProducts) {
        shippingPrice = 0;
      } else if (window.shippingConfig.freeShippingForFormat && this.areAllProductsWithFormat(items)) {
        shippingPrice = 0;
      } else if (window.shippingConfig && window.shippingConfig.freeShippingForBcl === true && items.find(item => item.choice?.bcl)) {
        shippingPrice = 0;
      } else if (window.shippingConfig && window.shippingConfig.shippingPriceByCountry[countryCode]) {
        shippingPrice = window.shippingConfig.shippingPriceByCountry[countryCode];
      } else if (window.shippingConfig && window.shippingConfig.shippingPriceOtherCountries) {
        shippingPrice = window.shippingConfig.shippingPriceOtherCountries;
      }
    }

    if (items.length && !window.shippingConfig) {
      for(const item of items) {
        if (item.choice.shippingPriceByCountry && item.choice.shippingPriceByCountry[countryCode]) {
          shippingPrice += parseInt(item.choice.shippingPriceByCountry[countryCode]);
        }
      }
    }

    return shippingPrice;
  }
  static getQuantities(items) {
    let quantities = 0;
    for (const item of items) {
      quantities += item.qty;
    }
    return quantities;
  }
  static areAllProductsBcl(items) {
    let allProductsAreBcl = true;
    for (const item of items) {
      if (!item.choice.bcl) {
        allProductsAreBcl = false;
      }
    }
    return allProductsAreBcl;
  }
  static freeShippingProgression(items) {
    if (!window.shippingConfig || !window.shippingConfig.freeShippingFromNumberOfProducts) {
      return;
    }
    if (this.getShippingPriceTotal(items) === 0) {
      return 100;
    }

    const quantities = this.getQuantities(items);
    const percentage = Math.floor(quantities * 100 / window.shippingConfig.freeShippingFromNumberOfProducts);

    if (percentage > 100) {
      return 100;
    }
    return percentage;
  }
  static areAllProductsWithFormat(items) {
    let allProductsAreWithFormat = true;
    for (const item of items) {
      if(!item.choice.format) {
        allProductsAreWithFormat = false;
      }

      if(!window.shippingConfig.freeShippingForFormat.includes(item.choice.format)) {
        allProductsAreWithFormat = false;
      }

    }
    return allProductsAreWithFormat;
  }
}
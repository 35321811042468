<template>
  <div v-if="content"
       v-html="renderHTML(content)"
       :data-product-component-type="`productcontent-${contentkey}`"
       :class="contentkey"></div>
  <meta v-if="shouldShowMeta" :itemprop="contentkey" :content="renderHTML(content)"/>
</template>

<script>
export default {
  name: 'ProductContent',
  props: {
    contentkey: {
      type: String,
      required: true,
      default: 'description'
    },
    product: {
      type: Object
    }
  },
  data() {
    return {
      content: null
    };
  },
  mounted() {
    this.content = this.product?.shortDescription || window.productDetails[this.contentkey];
  },
  computed: {
    shouldShowMeta() {
      return this.contentkey === 'description';
    }
  },
}
</script>

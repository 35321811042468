<template>
    <ul class="main-menu ">
        <li v-for="menuItem in menu?.items" :data-position="menuItem.position" :data-id="menuItem.id"
            class="category-item ui-menu-item level1">
            <a :href="menuItem.url">
                <img v-if="menuItem.icon" class="img-fluid category-level1-icon" :src="menuItem.icon" :alt="menuItem.label">
                <span>{{ menuItem.label }}</span>
            </a>
            <ul v-if="menuItem.items?.length" class="subchildmenu">
                <li v-for="menuItem2 in menuItem.items" :data-position="menuItem2.position" :data-id="menuItem2.id"
                    class="category-item ui-menu-item level2">
                    <a :href="menuItem2.url">
                        <img v-if="menuItem2.icon" class="img-fluid category-level1-icon" :src="menuItem2.icon"
                             :alt=" menuItem2.label">
                        <span>{{ menuItem2.label }}</span>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'Menu',
    data() {
        return {
            menu: {items: []}
        }
    },
    mounted() {
        this.menu = window.menu;

        let menuItems = window.menu.items;
        menuItems = menuItems.filter(this.filterOnDisplayInMenu);

        menuItems.sort(this.sortOnPosition);

        for (const menuItem of menuItems) {
            if (menuItem.items) {
                menuItem.items = menuItem.items.filter(this.filterOnDisplayInMenu);
                menuItem.items.sort(this.sortOnPosition);
            }
        }

        this.menu.items = menuItems;
    },
    methods: {
        filterOnDisplayInMenu(item) {
            return item.displayInMenu;
        },
        sortOnPosition(a, b) {
            return a.position - b.position;
        },
    }
}
</script>
<template>
    <div class="customize-product-wrapper" v-if="isCustomizable">
        <label class="enable-customize-product" :for="`customization-${id}`">
            <span class="customize-product-checkbox-label">{{ $t('labels.customizeProduct') }}</span>
            <input class="customize-product-checkbox" type="checkbox" :id="`customization-${id}`" v-model="enableCustomize">
            <span class="checkmark"></span>
        </label>
        <div v-if="enableCustomize" class="custom-product row">
            <div
                v-for="(customFontOption, index) in customFonts"
                :key="index"
                :class="['font-choice', lowerCaseAndJoin(customFontOption), { selected: customFont === customFontOption }, 'col-md-3', 'col-sm-6', 'col-6']"
                @click="selectCustomFont(customFontOption)"
            >
                <span>
                    {{ customText ? customText : 'Votre texte' }}
                </span>
            </div>
            <div class="custom-text-wrapper form-group col-md-6 col-sm-12 col-12">
                <input id="customText" type="text" class="custom-product-input" placeholder="Votre texte" aria-describedby="customTextHelper" :maxlength="maxCharacterCustomizable" v-model="customText"/>
                <small id="customTextHelper" class="form-text text-muted">{{ customText.length === 0 ? $t('labels.customMaxCharacter') : $t('labels.customCharacterLeft')}}{{ maxCharacterCustomizable - customText.length }}</small>
            </div>
        </div>
    </div>
</template>

<script>
import Utils from "@/services/utils";
export default {
    name: 'CustomizeProduct',
    data() {
        return {
            customFont: "Palace Script MT",
            customFonts: ["Palace Script MT", "Baskerville"],
            customText: "",
            enableCustomize: false,
            isCustomizable: false,
            maxCharacterCustomizable: 0,
        }
    },
    props: {
        id: {
            type: Number,
            default: 0
        },
        product: {
            type: Object
        },
        customProduct: {
            type: Function,
            default: () => {}
        }
    },
    mounted() {
        if (this.product) {
            this.isCustomizable = this.product.isCustomizable;
            this.maxCharacterCustomizable = this.product.maxCharacterCustomizable ? parseInt(this.product.maxCharacterCustomizable) : 20;
        } else {
            this.isCustomizable = window.productDetails.isCustomizable;
            this.maxCharacterCustomizable = window.productDetails.maxCharacterCustomizable ? parseInt(window.productDetails.maxCharacterCustomizable) : 20;
        }
    },
    methods: {
        selectCustomFont(customFont) {
            this.customFont = customFont;
        },
        emitCustomProduct(customType) {
            this.customProduct(this.id, customType, this[customType]);
        },
        lowerCaseAndJoin(str) {
            return Utils.lowerCaseAndJoin(str);
        }
    },
    watch: {
        customText: function (val) {
            this.product ? this.emitCustomProduct('customText') : window.productDetails.customText = val;
        },
        customFont: function (val) {
            this.product ? this.emitCustomProduct('customFont') : window.productDetails.customFont = val;
        },
        enableCustomize: function (val) {
            console.log(val)
            if (!val) {
                this.customFont = "Palace Script MT";
                this.customText = "";
                this.$nextTick(() => {
                    if (this.product) {
                        this.customProduct(this.id, 'customFont', null);
                        this.customProduct(this.id, 'customText', null);
                    } else {
                        window.productDetails.customFont = this.customFont;
                        window.productDetails.customText = this.customText;
                    }
                });
            } else {
                if (this.product) {
                    this.customProduct(this.id, 'customFont', this.customFont);
                } else {
                    window.productDetails.customFont = this.customFont;
                }
            }
        }
    }
}
</script>
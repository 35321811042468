import Utils from "@/services/utils";

// All methods that will execute when app is fully mounted
document.addEventListener('vue-app-mounted', () => {
    Utils.handleConditionedContent();
    Utils.addConditionedContentListener();
    if (window.globalConfig?.scriptAvisVerifies && (window.productDetails || window.productList)) {
        Utils.insertScript(window.globalConfig.scriptAvisVerifies);
    }
    const scriptGalileo = document.head.querySelector('script[src="/technical/search/index.min.js"]');
    if (!scriptGalileo) {
        Utils.insertScript('/technical/search/index.min.js');
    }

});
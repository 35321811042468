<template>
    <div>
        <span class="promotion-label-container" v-if="promotionLabel">
            <span class="promotion-label">
                {{ promotionLabel }}
            </span>
        </span>
        <span class="price-wrapper">
            <span v-if="choice.oldPrice" class="old-price-wrapper">
                <span class="old-price">
                    <span class="amount">{{ oldPrice }}</span>
                    <span class="currency">&euro;</span>
                </span>
            </span>
            <span class="price" v-if="!choice.isSingleProduct && choice.hasOwnProperty('startingPrice') && choice.startingPriceDuration > 0">
                <span class="starting-offer">
                    <span class="amount">{{ startingPrice }}</span>
                    <span class="currency">&euro;</span>
                    <span class="starting-offer-detail">{{ startingPriceDuration }}</span>
                </span>
                <span class="after-starting-offer">
                    <span class="after-starting-offer-details">{{ $t('labels.then') }}&nbsp;</span>
                    <template v-if="choice.defaultPrice !== 0">
                        <span class="amount">{{ defaultPrice }}</span>
                        <span class="currency">&euro;</span>
                        <span class="after-starting-offer-details"> / {{ renewalTermLengthDuration }}</span>
                    </template>
                    <template v-else>
                        <span class="after-starting-offer-details">{{ freeLabel }}</span>
                    </template>
                </span>
            </span>
            <span class="price" v-else>
                <span class="amount">{{ unitPrice }}</span>
                <span class="currency">&euro;</span>
            </span>
            <span class="frequency">
                {{ frequency }}
            </span>
        </span>
    </div>
</template>

<script>
import Price from "@/services/price";
import Utils from "@/services/utils";

export default {
    name: 'ChoicePrice',
    props: {
        choice: {
            type: Object
        }
    },
    computed: {
        promotionLabel() {
            return this.choice.promotionLabel;
        },
        oldPrice() {
            return this.formatPrice(this.choice.oldPrice);
        },
        startingPrice() {
            return this.formatPrice(this.choice.startingPrice);
        },
        defaultPrice() {
            return this.formatPrice(this.choice.defaultPrice);
        },
        unitPrice() {
            return this.formatPrice(this.choice.unitPrice);
        },
        startingPriceDuration() {
            return this.displayDuration(this.choice.startingPriceDuration, true);
        },
        renewalTermLengthDuration() {
            return this.displayDuration(this.choice.renewalTermLength);
        },
        freeLabel() {
            return this.choice.freeLabel;
        },
        frequency() {
            return this.choice.frequency;
        }
    },
    methods: {
        displayDuration(duration, startingPrice = false) {
            return Utils.displayDuration(duration, this.$i18n.locale, startingPrice);
        },
        formatPrice(price) {
            return Price.format(price);
        }
    }
}
</script>
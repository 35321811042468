<template>
    <div v-if="!isDraftOrderSent || isLoading" class="loading"></div>
    <div v-else class="div-1click text-center">
        <div class="warning-message-1click">
            <strong>{{ $t('contactForm.warningMessage1click') }}</strong>
        </div>
        <button type="button" class="btn btn-lg btn-success btn-1click my-3" @click.prevent="oneClickAction">
            {{ $t('contactForm.buttonOrder1click') }}
        </button>
        <div class="cgv-1c">
            <p>
                <a :href="cgvUrl">
                    {{ `${$t('contactForm.termsAndConditions').charAt(0).toUpperCase()}${$t('contactForm.termsAndConditions').slice(1)}` }} {{ brandName }}
                </a>
            </p>
        </div>
    </div>
</template>

<script>
import {useDraftStore} from '@/store/draft.js';
import {constants} from "@/constants";
import {useCartStore} from "@/store/cart";
import CartService from "@/services/cart";
import Orion from "@/services/orion";
import Redirect from "@/services/redirect";
import {useOrderHistoryStore} from "@/store/orderHistory";

export default {
    name: 'CheckoutOneClick',
    data() {
        return {
            isLoading: false
        }
    },
    computed: {
        isDraftOrderSent() {
            return useDraftStore().isDraftOrderSent;
        },
        cgvUrl() {
          return window.globalConfig.termsAndConditionsUrl;
        },
        brandName() {
            return window.globalConfig.brandName;
        }
    },
    methods: {
        async oneClickAction(event) {
            this.isLoading = true;
            const alreadyClicked = await Orion.is1ClickLinkAlreadyUsed();
            if (!alreadyClicked.status) {
                const currentOrder = useOrderHistoryStore().orderHistory.slice(-1)[0].order;
                const data = Orion.prepareOrder(useCartStore().cart, currentOrder, {orderType: 'initial'}, true);

                const minifiedCart = CartService.minifyCart();

                await Orion.sendOrder(minifiedCart, data, window.globalConfig.domainId, 'createOneClick');

                const paramPlusData = Redirect.getParamPlusData();
                Redirect.redirectToConfirmation(paramPlusData);
            } else {
                this.isLoading = false;
                const btn1Click = document.querySelector('.btn.btn-lg.btn-success.btn-1click');
                btn1Click.outerHTML = `<div>${this.$t('contactForm.alreadyClicked')}</div>`
            }
        }
    }
}
</script>
import { defineStore } from 'pinia';
import Utils from "@/services/utils";
import {constants} from "@/constants";

export const useDraftStore = defineStore('draft', {
  state: () => ({
    _selectedChoice: null,
    _selectedPaymentMethod: null,
    _selectedCountryCode: null,
    _sendingDraftOrder: null, // integer used by setTimeout
    _cgvApproved: false,
    _cgsApproved: false,
    _formValid: false,
    _order: {externalOrderId: Utils.generateUID()},
    _prefillLoading: false,
    _isEligibleToOneClick: false,
    _fullAddress: '',
    _draftOrderSent: false,
    _actionLegacy: 'create',
    _has1ClickOptOut: false,
    _isDistinctAdress: false,
  }),
  getters: {
    selectedChoice: (state) => {
      return state._selectedChoice;
    },
    selectedPaymentMethod: (state) => {
      return state._selectedPaymentMethod;
    },
    selectedCountryCode: (state) => {
      return state._selectedCountryCode;
    },
    isSendingDraftOrder: (state) => {
      return state._sendingDraftOrder;
    },
    areCgvApproved: (state) => {
      return state._cgvApproved;
    },
    areCgsApproved: (state) => {
      return state._cgsApproved;
    },
    isFormValid: (state) => {
      return state._formValid;
    },
    order: (state) => {
      return state._order;
    },
    isPrefillLoading: (state) => {
      return state._prefillLoading;
    },
    isEligibleToOneClick: (state) => {
      return state._isEligibleToOneClick;
    },
    fullAddress: (state) => {
      return state._fullAddress;
    },
    isDraftOrderSent: (state) => {
      return state._draftOrderSent;
    },
    actionLegacy: (state) => {
      return state._actionLegacy;
    },
    has1ClickOptOut: (state) => {
      if (!state._has1ClickOptOut) {
        state._has1ClickOptOut = localStorage.getItem(constants.HAS_1_CLICK_OPTOUT) === 'true';
      }

      return state._has1ClickOptOut;
    },
    isDistinctAdress: (state) => {
      return state._isDistinctAdress;
    }
  },
  actions: {
    selectChoice(choice) {
      this._selectedChoice = choice;
    },
    selectPaymentMethod(paymentMethod) {
      this._selectedPaymentMethod = paymentMethod;
    },
    selectCountryCode(countryCode) {
      this._selectedCountryCode = countryCode;
    },
    sendingDraftOrder(sending) {
      this._sendingDraftOrder = sending;
    },
    approveCgv(approved) {
      this._cgvApproved = approved;
    },
    approveCgs(approved) {
      this._cgsApproved = approved;
    },
    formValid(valid) {
      this._formValid = valid;
    },
    updateOrder(order) {
      Object.assign(this._order, order);
    },
    prefillLoading(loading) {
      this._prefillLoading = loading;
    },
    eligibleToOneClick(eligible) {
      this._isEligibleToOneClick = eligible;
    },
    updateFullAddress(address) {
      this._fullAddress = address;
    },
    draftOrderSent(sent) {
      this._draftOrderSent = sent;
    },
    updateActionLegacy(action) {
      this._actionLegacy = action;
    },
    updateHas1ClickOptOut(has1ClickOptOut) {
      localStorage.setItem(constants.HAS_1_CLICK_OPTOUT, has1ClickOptOut);
      this._has1ClickOptOut = has1ClickOptOut;
    },
    updateIsDistinctAdress(isDistinctAdress) {
      this._isDistinctAdress = isDistinctAdress;
    }
  }
});
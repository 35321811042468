<template>
    <div class="enter-your-token" v-if="showEnterYourToken">
        <div id="root">
            <div class="text-center">
                <img src="https://pf-cdn.pubfac.com/uploads/pal/logo.png" alt="palyance" class="img-fluid mb-3" width="160">
                <p class="alert alert-primary text-center fs-6">{{ $t('messages.yourOrderIsWellSavedAndFreezedFor30mins') }}</p>
                <div v-if="displayCountdown" class="countdown mb-3">
                    <div class="countdown-number">{{ countdownToString }}</div>
                    <svg>
                        <circle r="48" cx="50" cy="50"></circle>
                    </svg>
                </div>
                <h4 class="mb-2">{{ $t('labels.enterYourToken') }}</h4>
                <p class="text-muted text-center mb-3">{{ $t('messages.weHaveSentItThoughMail') }} <span>{{ billingEmail }}</span></p>
                <div class="input-group-token">
                    <input ref="firstDigit" type="text" class="input-number-token" maxlength="6" v-model="firstDigit"
                           :disabled="tokenIsValidating"
                           @paste.prevent="pasteCode"
                           @focus="$event.target.select()"
                           @keyup.enter="$refs.secondDigit.focus()"
                           @keyup.left="$event.target.select()"
                           @keyup.right="$refs.secondDigit.focus()">
                    <input ref="secondDigit" type="text" class="input-number-token" maxlength="5" v-model="secondDigit"
                           :disabled="tokenIsValidating"
                           @focus="$event.target.select()"
                           @keyup.enter="$refs.thirdDigit.focus()"
                           @keyup.left="$refs.firstDigit.focus()"
                           @keyup.right="$refs.thirdDigit.focus()"
                           @keyup.delete="secondDigit === '' && $refs.firstDigit.focus()">
                    <input ref="thirdDigit" type="text" class="input-number-token" maxlength="4" v-model="thirdDigit"
                           :disabled="tokenIsValidating"
                           @focus="$event.target.select()"
                           @keyup.enter="$refs.fourthDigit.focus()"
                           @keyup.left="$refs.secondDigit.focus()"
                           @keyup.right="$refs.fourthDigit.focus()"
                           @keyup.delete="thirdDigit === '' && $refs.secondDigit.focus()">
                    <input type="text" class="input-number-token input-number-dash" value="-" disabled>
                    <input ref="fourthDigit" type="text" class="input-number-token" maxlength="3" v-model="fourthDigit"
                           :disabled="tokenIsValidating"
                           @focus="$event.target.select()"
                           @keyup.enter="$refs.fifthDigit.focus()"
                           @keyup.left="$refs.thirdDigit.focus()"
                           @keyup.right="$refs.fifthDigit.focus()"
                           @keyup.delete="fourthDigit === '' && $refs.thirdDigit.focus()">
                    <input ref="fifthDigit" type="text" class="input-number-token" maxlength="2" v-model="fifthDigit"
                           :disabled="tokenIsValidating"
                           @focus="$event.target.select()"
                           @keyup.enter="$refs.sixthDigit.focus()"
                           @keyup.left="$refs.fourthDigit.focus()"
                           @keyup.right="$refs.sixthDigit.focus()"
                           @keyup.delete="fifthDigit === '' && $refs.fourthDigit.focus()">
                    <input ref="sixthDigit" type="text" class="input-number-token" maxlength="1" v-model="sixthDigit"
                           :disabled="tokenIsValidating"
                           @focus="$event.target.select()"
                           @keyup.left="$refs.fifthDigit.focus()"
                           @keyup.right="$event.target.select()"
                           @keyup.delete="sixthDigit === '' && $refs.fifthDigit.focus()">
                </div>
                <div v-if="showPasteButton" class="mt-3">
                    <button type="button" class="btn btn-sm btn-secondary px-4 text-uppercase" @click="clickPasteCode">{{ $t('labels.paste') }}</button>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="loading"></div>
</template>
<script>
import Palyance from "@/services/palyance";
import Redirect from "@/services/redirect";
import Curiosity from "@/services/curiosity";
import {useOrderHistoryStore} from "@/store/orderHistory";
import AlreadyOrderedProducts from "@/services/alreadyOrderedProducts";
import {useDraftStore} from "@/store/draft";

export default {
    name: 'WaitingDoubleOptin',
    mounted() {
        this.main();
        setInterval(() => {
            this.countdown--;
            if (!this.countdown) {
                Redirect.redirectToCheckout(this.paramPlusData);
            }
        }, 1000);
    },
    computed: {
        showPasteButton() {
            return this.permissionStatus.state === 'granted';
        },
        token() {
            const tokenCharaters = [];
            tokenCharaters.push(this.firstDigit, this.secondDigit, this.thirdDigit, this.fourthDigit, this.fifthDigit, this.sixthDigit);
            return tokenCharaters.join('');
        },
        billingEmail() {
            return useOrderHistoryStore().orderHistory.slice(-1)[0].order.billingEmail;
        },
        displayCountdown() {
            return this.countdown > 0;
        },
        countdownToString() {
            const minutes = this.formatDigit(Math.floor(this.countdown / 60));
            const secondes = this.formatDigit(this.countdown % 60);
            return `${minutes}:${secondes}`
        }
    },
    data() {
        return {
            countdown: 1800,
            firstDigit: '',
            secondDigit: '',
            thirdDigit: '',
            fourthDigit: '',
            fifthDigit: '',
            sixthDigit: '',
            tokenIsValidating: false,
            paramPlusData: null,
            showEnterYourToken: false,
            showPasteButton: {
                state: 'denied'
            },
        }
    },
    watch: {
        firstDigit(value) {
            if (String(value).length > 1) {
                this.firstDigit = value.slice(-1);
            }

            if (String(value).length === 1) {
                this.$refs.secondDigit.focus();
            }
        },
        secondDigit(value) {
            if (String(value).length > 1) {
                this.secondDigit = value.slice(-1);
            }

            if (String(value).length === 1) {
                this.$refs.thirdDigit.focus();
            }
        },
        thirdDigit(value) {
            if (String(value).length > 1) {
                this.thirdDigit = value.slice(-1);
            }

            if (String(value).length === 1) {
                this.$refs.fourthDigit.focus();
            }
        },
        fourthDigit(value) {
            if (String(value).length > 1) {
                this.fourthDigit = value.slice(-1);
            }

            if (String(value).length === 1) {
                this.$refs.fifthDigit.focus();
            }
        },
        fifthDigit(value) {
            if (String(value).length > 1) {
                this.fifthDigit = value.slice(-1);
            }

            if (String(value).length === 1) {
                this.$refs.sixthDigit.focus();
            }
        },
        sixthDigit(value) {
            if (String(value).length > 1) {
                this.sixthDigit = value.slice(-1);
            }
        },
        token(token) {
            this.validateToken(token);
        }
    },
    methods: {
        main() {
            this.paramPlusData = Redirect.getParamPlusData();
            const queryParams = Redirect.checkAndGetQueryParams(this.paramPlusData);

            if (!this.paramPlusData || !this.paramPlusData.orderId) {
                Redirect.redirectToError(this.paramPlusData);
                return;
            }

            setInterval(() => {
                this.hasClientValidatedDoubleOptin(this.paramPlusData.orderId, data => {
                    if (data.status === 'OK') {
                        this.validateToken(null);
                    } else {
                        this.showEnterYourToken = true;
                    }
                });
            }, 5000);
        },
        hasClientValidatedDoubleOptin(orderId, callback) {
            Palyance.hasClientValidatedDoubleOptin(orderId).then(data => {
                console.log(JSON.stringify(data), orderId);
                callback(data);
            }).catch(error => {
                const message = `Error while getting palyance double optin status : error: ${error.toString()}, orderId: ${orderId}`;
                console.error(message);
                callback(null, new Error(message));
            });
        },
        validateToken(token) {
            Palyance.postToken(this.paramPlusData.orderId, token).then(response => {
                if (response.valid) {
                    Curiosity.addCoregs(this.billingEmail, this.paramPlusData).finally(() => {
                        AlreadyOrderedProducts.updateAfterPayment()
                        useDraftStore().updateHas1ClickOptOut(false);
                        Redirect.redirectToConfirmation(this.paramPlusData);
                    }).catch(error => {
                        console.error(error);
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        async clickPasteCode() {
            this.permissionStatus = await navigator.permissions.query({
                name: 'clipboard-read',
                allowWithoutGesture: false
            });
            console.log('clickPasteCode:his.permissionStatus', this.permissionStatus);

            if (this.permissionStatus.state === 'granted') {
                const data = await navigator.clipboard.readText();
                this.setTokenFromDigits(data);
            }
        },
        pasteCode(event) {
            const data = event.clipboardData.getData('text/plain');
            this.setTokenFromDigits(data);
        },
        setTokenFromDigits(data) {
            const [firstDigit, secondDigit, thirdDigit, fourthDigit, fifthDigit, sixthDigit] = data;

            this.firstDigit = typeof firstDigit === 'undefined' ? this.firstDigit : firstDigit || '';
            this.secondDigit = typeof secondDigit === 'undefined' ? this.secondDigit : secondDigit || '';
            this.thirdDigit = typeof thirdDigit === 'undefined' ? this.thirdDigit : thirdDigit || '';
            this.fourthDigit = typeof fourthDigit === 'undefined' ? this.fourthDigit : fourthDigit || '';
            this.fifthDigit = typeof fifthDigit === 'undefined' ? this.fifthDigit : fifthDigit || '';
            this.sixthDigit = typeof sixthDigit === 'undefined' ? this.sixthDigit : sixthDigit || '';
        },
        formatDigit(number) {
            return number < 10 ? `0${number}` : number;
        }
    }
}
</script>
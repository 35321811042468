export default class AbTests {
  static electItemOfGroup(abGroup) {
    // TODO : Implement fingerprintJS
    //console.log('choosing an element in', abGroup);

    // [{w: 0,5}, {w: O,2}, {w: 0,3}]
    // Accumulated weights :
    // 0     .5  .7   1
    // [_____][__][___]
    // Ex: random pick : 0.6
    // [_____][*_][___]
    // Elected : 2nd object : {w: 0,2}

    const randomPick = Math.random();

    let accumulatedWeight = 0;
    for (const item of abGroup) {
      accumulatedWeight += parseInt(item.weight) / 100;
      if (randomPick <= accumulatedWeight) {
        //console.log('elected item', randomPick, item);
        return item;
      }
    }
  }
}
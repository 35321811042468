import axios from "axios";
import {constants} from "@/constants";

export default class Curiosity {
    static callAdd(query) {
        return new Promise((resolve, reject) => {
            const configGrapes = localStorage.getItem(constants.CONFIG_GRAPES);
            const curiosityAddPath = configGrapes ? JSON.parse(configGrapes).curiosity?.add : '';

            if (!curiosityAddPath) {
                reject("No curiosity add path found in the 'config-grapes' key in the localStorage. Cannot call Curiosity::callAdd");
                return;
            }

            const url = `${curiosityAddPath}?${query}`;
            console.info('Calling Curiosity add with url', url);

            resolve(axios.get(url));
        });
    }

    static addCoregs(email, parameters){
        return new Promise((resolve, reject) => {
            const coregsParam = window.globalConfig.shopCoregs ? '&sourceCodes=' + JSON.stringify(window.globalConfig.shopCoregs.split(';')) : '';

            if (!coregsParam) {
                console.warn("No coregs in the 'shopCoregs' key of the 'window.globalConfig' object. Cannot add coregs in Curiosity::addCoregs");
                resolve();
                return;
            }

            if (!email) {
                reject("No email provided in Curiosity::addCoregs");
                return;
            }

            let query = 'email=' + email.toLowerCase() + coregsParam;

            if (Object.keys(parameters).length) {
                const paramsWhiteListMapping = [
                    {
                        keyToSend: 'orderTrackingCode',
                        keyValue: 'trackingCode'
                    },
                    {
                        keyToSend:'leadTrackingCode',
                        keyValue: 'leadTrackingCode'
                    }
                ];
                const params = {};
                for (const key of paramsWhiteListMapping) {
                    if (parameters[key.keyValue]) {
                        params[key.keyToSend] = parameters[key.keyValue];
                    }
                }
                if (Object.keys(params).length) {
                    query += '&params=' + encodeURI(JSON.stringify(params));
                }
            }

            Curiosity.callAdd(query).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    }
}
<template>
    <div v-if="displayRating && productCode">
      <template v-if="mode === 'skeepers_product__reviews'">
        <div
          :class="mode"
          :data-product-id="productCode"
          :data-locale="locale"
          :data-price="productPrice"
          data-currency="EUR"
          :data-name="productName"
          :data-url="productUrl"
          :data-image-url="productMainImage"
        >
        </div>
      </template>
      <template v-else>
        <div :class="mode" :data-product-id="productCode"></div>
      </template>
    </div>
</template>

<script>
import Price from '@/services/price';

export default {
    name: 'ProductRatings',
    data() {
        return {
            displayRating: true,
            productCode: null,
            productName: null,
            productPrice: null,
            productMainImage: null,
            productUrl: null,
            locale: null,
        }
    },
    props: {
        mode: {
            type: String,
            default: 'skeepers_product__reviews'
        },
        productCodeFromParent: {
            type: String,
            default: null
        }
    },
    mounted() {
        if (window.productDetails) {
            this.displayRating = !window.productDetails.hideCustomerReviews;
            this.productCode = window.productDetails.code;
            this.productName = window.productDetails.name;
            const defaultChoice = window.productDetails.choices.find(choice => choice.default);
            this.productPrice = defaultChoice? this.formatPrice(defaultChoice.defaultPrice) : null;
            this.productMainImage = window.productDetails.images.find(image => image.type === 'main')?.src;
            this.productUrl = window.location.href;
            const lang = window.globalConfig.lang;
            this.locale = `${lang}_${lang.toUpperCase()}`;
        } else if (this.productCodeFromParent) {
            this.displayRating = true;
            this.productCode = this.productCodeFromParent
        }
    },
    methods: {
        formatPrice(price) {
            return Price.format(price);
        }
    }
}
</script>
<template>
    <div v-if="argument5" v-html="renderHTML(argument5)"></div>
</template>

<script>
export default {
    name: 'ProductArgument5',
    props: {
        product: {
            type: Object
        }
    },
    data() {
        return {
            argument5: null
        }
    },
    mounted() {
        this.argument5 = this.product?.argument5 || window.productDetails.argument5;
    }
}
</script>
<template>
    <div v-if="argument4" v-html="renderHTML(argument4)"></div>
</template>

<script>
export default {
    name: 'ProductArgument4',
    props: {
        product: {
            type: Object
        }
    },
    data() {
        return {
            argument4: null
        }
    },
    mounted() {
        this.argument4 = this.product?.argument4 || window.productDetails.argument4;
    }
}
</script>
import {constants} from "@/constants";

export default class MultiLocalStorage {
    static checkCleanOrderHistory() {
        // clean local storage if it's too old
        const localStorageName = constants.ECOM_ORDER_HISTORY;

        const localStorageGc = localStorage.getItem(constants.LOCAL_STORAGE_GC);
        if (!localStorageGc) {
            localStorage.removeItem(localStorageName);
            localStorage.setItem(
                constants.LOCAL_STORAGE_GC,
                JSON.stringify({cleaningOrderCheck: (new Date()).getTime()})
            );
        } else {
            const localStorageGcJson = JSON.parse(localStorageGc);
            if (localStorageGcJson.cleaningOrderCheck < (new Date()).getTime() - 1000 * 60 * 60 * 24) { // 1 day
                localStorage.removeItem(localStorageName);
                localStorage.setItem(
                    constants.LOCAL_STORAGE_GC,
                    JSON.stringify({cleaningOrderCheck: (new Date()).getTime()})
                );
            } else {
                localStorage.setItem(
                    constants.LOCAL_STORAGE_GC,
                    JSON.stringify({cleaningOrderCheck: (new Date()).getTime()})
                );
            }
        }
    }

    static get(localStorageName, domainId) {
        if (!domainId) {
            console.warn('Trying to get unscoped domain value from localStorage !');
        }
        let finalData = null;

        const rawData = localStorage.getItem(localStorageName);
        if (rawData !== null) {
            const data = JSON.parse(rawData);
            if (data.length !== undefined && domainId) {
                finalData = data.find(item => item.domainId === domainId);
            } else if (data.length !== undefined) {
                finalData = data[0];
            } else {
                finalData = data;
            }
        }

        if (finalData && finalData.fromArray) {
            delete finalData.fromArray;
            delete finalData.domainId;
            finalData = Object.values(finalData);
        }

        return finalData;
    }

    static set(localStorageName, domainId, newData, merge = false) {
        if (!domainId) {
            console.warn('Trying to set unscoped domain value from localStorage !');
        }
        if (Array.isArray(newData)) {
            newData = Object.assign({}, newData); // transform array to object
            newData.fromArray = true;
        }
        if (domainId) {
            newData.domainId = domainId;
        }

        const rawData = localStorage.getItem(localStorageName);

        if (rawData !== null) {
            let data = JSON.parse(rawData);
            if (data.length !== undefined) {
                // local storage is an array of data
                if (domainId) {
                    let scopedData = data.find(cart => cart.domainId === domainId);
                    if (scopedData) {
                        // data exists for the domain in local storage
                        if (merge) {
                            Object.assign(scopedData, newData); // data is updated by reference (and scopedData is merged with old data)
                        } else {
                            // ensure that object is empty before adding new data
                            for (const key in scopedData) {
                                delete scopedData[key];
                            }
                            for (const key in newData) {
                                scopedData[key] = newData[key];
                            }
                        }
                    } else {
                        // there is no data for current domain in local storage
                        data.push(newData);
                    }
                } else {
                    // if, for any reason, we don't have a domainId, we just push the data
                    data.push(newData);
                }
            } else {
                // old data format when local storage was a single data => switch to array of data
                data = [newData];
            }

            localStorage.setItem(localStorageName, JSON.stringify(data));
        } else {
            localStorage.setItem(localStorageName, JSON.stringify([newData]));
        }
    }
}
<template>
    <div v-if="breadcrumbs" class="breadcrumbs">
        <ol itemscope itemtype="https://schema.org/BreadcrumbList">
            <li v-for="(breadcrumb, index) in breadcrumbs" class="breadcrumb" itemprop="itemListElement" itemscope
                itemtype="https://schema.org/ListItem">
                <template v-if="index+1 === breadcrumbs.length">
                    <strong itemprop="item"><span itemprop="name">{{ breadcrumb.label }}</span></strong>
                </template>
                <template v-else>
                    <a :href="baseUrl + breadcrumb.url" itemprop="item"><span itemprop="name">{{ breadcrumb.label }}</span></a>
                </template>
                <meta itemprop="position" :content="index"/>
            </li>
        </ol>
    </div>
</template>

<script>
import {useSessionStore} from "@/store/session";

export default {
    name: 'Breadcrumbs',
    data() {
        return {
            breadcrumbs: []
        }
    },
    computed: {
        baseUrl() {
            return window.globalConfig.siteBaseUrl;
        },
        endBreacrumbs() {
            return window.productDetails ? {
                label: window.productDetails.name,
                url: window.productDetails.slug
            } : {};
        }
    },
    mounted() {
        if (window.category && window.category.currentCategoryPath) {
            this.breadcrumbs = this.getCategoriesBreadcrumbs();
        }

        if (window.productDetails && window.productDetails.categories) {
            this.breadcrumbs = this.getProductsBreadcrumbs();
        }
    },
    methods: {
        getCategoriesBreadcrumbs() {
            const breadcrumbs = [{
                label: this.$t('labels.home'),
                url: ''
            }];
            if (window.category && window.category.currentCategoryPath) {
                const categoryPath = window.category.currentCategoryPath;
                categoryPath.forEach((link) => {
                    breadcrumbs.push({
                        label: link.label,
                        url: link.url
                    });
                });
            }
            return breadcrumbs;
        },
        getProductsBreadcrumbs() {
            const breadcrumbs = [{
                label: this.$t('labels.home'),
                url: ''
            }];
            let category = null;

            if (!useSessionStore().session) {
                category = this.findCategoryWithMostParents(window.productDetails.categories);
            } else {
                let history = useSessionStore().session?.history;
                let lastCategoryPage = history?.findLast(page => page.type === 'category');
                if (lastCategoryPage) {
                    category = window.productDetails.categories.find(cat => cat.id === lastCategoryPage.id);
                } else {
                    category = this.findCategoryWithMostParents(window.productDetails.categories);
                }
            }
            if (!category) {
                breadcrumbs.push(this.endBreacrumbs);
                return breadcrumbs;
            }

            const parentCategories = this.generateArrayWithAllCategories(category);
            breadcrumbs.push(...parentCategories.reverse());

            breadcrumbs.push(this.endBreacrumbs);

            return breadcrumbs;
        },
        generateArrayWithAllCategories(category) {
            const categories = [{
                label: category.label,
                url: category.url
            }];
            while (category.parent) {
                category = category.parent;

                if (categories.find(item => item.url === category.url)) {
                    continue;
                }
                categories.push({
                    label: category.label,
                    url:  category.url
                });
            }
            return categories;
        },
        countParents(category) {
            let count = 0;
            while (category.parent) {
                category = category.parent;
                count++;
            }
            return count;
        },
        findCategoryWithMostParents(categories) {
            let maxParents = -1;
            let categoryWithMostParents = null;

            categories.forEach(category => {
                const parentsCount = this.countParents(category);
                if (parentsCount > maxParents) {
                    maxParents = parentsCount;
                    categoryWithMostParents = category;
                }
            });

            return categoryWithMostParents;
        }
    }
}
</script>
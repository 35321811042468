<template>
    <template v-if="cart?.items?.length">
        <span v-if="label" class="coupon-label">{{label}}</span>
        <form method="post" action="" @submit.prevent="applyCouponAction" :class="formCssClass">
            <input v-model="coupon" type="text" :placeholder="$t('labels.placeholderCoupon') " name="coupon" :class="inputCssClass"/>
            <button type="submit" name="applycoupon" :class="btnCssClass">{{ $t('labels.applycoupon') }}</button>
        </form>
    </template>
    <div v-for="message in messages" :class="`alert alert-${message.type}`" role="alert">
        {{ message.message }}
    </div>
</template>
<script>
import {useCartStore} from '@/store/cart.js';
import CouponService from "@/services/coupon";

export default {
    name: 'CartCoupon',
    props: {
      label: {
        type: String,
        default: null
      },
      formCssClass: {
        type: String,
        default: ''
      },
      inputCssClass: {
        type: String,
        default: ''
      },
      btnCssClass: {
        type: String,
        default: ''
      },
    },
    data() {
        return {
            cart: null,
            coupon: '',
            messages: [],
            messageTimeout: null
        }
    },
    computed: {
        lastCartUpdate() {
            return useCartStore().lastUpdate;
        },
    },
    watch: {
        lastCartUpdate: {
            handler(newValue, oldValue) {
                if (!window?.globalConfig?.domainId) {
                    return;
                }
                this.cart = this.getCart();
            },
        },
        messages: {
            handler(newValue, oldValue) {
                if (this.messages.length === 0) {
                    return;
                }
                if (this.messageTimeout) {
                    window.clearTimeout(this.messageTimeout);
                }
                this.messageTimeout = window.setTimeout(() => {
                    this.messages = [];
                }, 5000);
            },
            deep: true
        }
    },
    mounted() {
        this.cart = this.getCart();
    },
    methods: {
        getCart() {
            return useCartStore().cart;
        },
        applyCouponAction(event) {
            if (this.coupon === '') {
                this.messages.push({'type': 'danger', 'message': this.$t('messages.emptyCoupon')});
                return;
            }

            this.coupon = this.coupon.toUpperCase().trim();
            const minifiedCart = CouponService.addCouponInMinifiedCart(this.coupon)

            CouponService.checkCoupons(minifiedCart).then((response) => {
                for (const coupon of Object.values(response.data.cart.coupons)) {
                    if (coupon.isValid === false) {
                        let translation = '';
                        if (this.$te(`messages.invalidCoupon.${coupon.reason}`)) {
                            translation = this.$t(`messages.invalidCoupon.${coupon.reason}`, {
                                invalidCouponCode: coupon.code,
                                invalidCouponReason: coupon.reason
                            });
                        } else {
                            translation = this.$t('messages.invalidCoupon.DEFAULT', {
                                invalidCouponCode: coupon.code,
                                invalidCouponReason: coupon.reason
                            });
                        }
                        this.messages.push({
                            type: 'danger',
                            message: translation
                        });
                    } else {
                        this.messages.push({
                            type: 'success',
                            message: this.$t('messages.successfulCoupon', {validCouponCode: coupon.code})
                        });
                    }
                }
            }).catch((error) => {
                this.messages.push({'type': 'danger', 'message': this.$t('messages.errorCoupon')});
            });
        }
    }
}
</script>
<template>
    <span class="visualStock" v-if="visualStock" v-html="renderHTML(visualStock)"></span>
</template>

<script>
export default {
    name: 'ProductVisualStock',
    props: {
        product: {
            type: Object
        }
    },
    data() {
        return {
            visualStock: '',
        }
    },
    mounted() {
        this.visualStock = this.product?.visualStock || window.productDetails.visualStock;
    }
}
</script>
<template>
    <img v-if="main" class="img-fluid main-image" :src="main.src" :alt="mainText" />
    <ul class="additional-images">
        <li v-for="image in images">
            <template v-if="image.type !== 'featuredImage'">
                <img @click.prevent="clickAction" :src="image.src" :class="`img-fluid product-image additional ${image.current ? 'current': ''}`" :alt="additionalText"/>
                <meta v-if="image.type === 'main'" itemprop="image" :content="image.src"/>
            </template>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'ProductImages',
    props: {
        product: {
            type: Object
        }
    },
    data() {
        return {
            images: [],
            main: null,
            mainText: '',
            additionalText: ''
        }
    },
    mounted() {
        this.images = this.product?.images || window.productDetails.images;
        this.main = this.images.filter(image => image.type === 'main')[0];
        this.mainText = this.product?.name || window.productDetails.name;
        this.additionalText = this.product?.name || window.productDetails.name;
    },
    methods: {
        clickAction(event) {
            this.main = this.images.filter(image => image.src === event.target.src)[0];

            // if main is undefined we try to refresh images from productDetails
            if (!this.main) {
                if (this.product) {
                    this.images = this.product.images;
                } else {
                    this.images = window.productDetails.images;
                }
                this.main = this.images.filter(image => image.src === event.target.src)[0];
            }
        }
    },
    watch: {
        main() {
            this.images.forEach(image => {
                image.current = image.src === this.main.src;
            });
        }
    }
}
</script>
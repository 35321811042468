<template>
    <template v-if="isImageGrid">
        <div class="products-grid-wrapper">
            <TransitionGroup name="pagination-effect-grid">
                <template v-for="(product, index) in products" :key="product.id">
                    <template v-if="index % imageEachXElements === 0 && index !== 0 && category[`gridImage${index/imageEachXElements}`]">
                        <div class="image-large-push">
                            <template v-if="category[`gridImageLink${index/imageEachXElements}`]">
                                <a :href="category[`gridImageLink${index/imageEachXElements}`]">
                                    <img class="img-fluid" :src="category[`gridImage${index/imageEachXElements}`]" alt="" />
                                </a>
                            </template>
                            <template v-else>
                                <img class="img-fluid" :src="category[`gridImage${index/imageEachXElements}`]" alt="" />
                            </template>
                        </div>
                    </template>
                    <div class="products-grid-block">
                        <div class="product-item"
                             v-bind:data-product-id="product.id">
                            <ProductGrid
                                :product="product"
                                :categoryid="categoryid"
                                :showaddtocart="showaddtocart"
                                :addToCartLabel="addToCartLabel"
                                :showprocessorder="showprocessorder"
                                :processordertext="processordertext"
                                :showratingstar="showratingstar"
                                :getDefaultChoice="getDefaultChoice"
                                :fillProductWithChoice="fillProductWithChoice"
                                :isProductListFromParent="isProductListFromParent"
                            />
                        </div>
                    </div>
                </template>
            </TransitionGroup>
        </div>
    </template>
    <template v-else>
        <div class="row justify-content-center">
            <TransitionGroup name="pagination-effect-grid">
                <div class="product-item col-lg-3 col-md-4 col-sm-6" v-for="product in products"
                     v-bind:data-product-id="product.id" :key="product.id">
                    <ProductGrid
                        :product="product"
                        :categoryid="categoryid"
                        :showaddtocart="showaddtocart"
                        :addToCartLabel="addToCartLabel"
                        :showprocessorder="showprocessorder"
                        :processordertext="processordertext"
                        :showratingstar="showratingstar"
                        :getDefaultChoice="getDefaultChoice"
                        :fillProductWithChoice="fillProductWithChoice"
                        :isProductListFromParent="isProductListFromParent"
                    />
                </div>
            </TransitionGroup>
        </div>
    </template>
</template>

<script>

import ProductGrid from "@/components/productlist/subcomponents/ProductGrid.vue";

export default {
    name: 'ProductListGrid',
    props: {
        products: {
            type: Array,
            default: () => []
        },
        categoryid: {
            type: String,
            default: ''
        },
        showaddtocart: {
            type: Boolean,
            default: false
        },
        addToCartLabel: {
            type: String
        },
        showprocessorder: {
            type: Boolean,
            default: false
        },
        processordertext: {
            type: String
        },
        showratingstar: {
            type: Boolean,
            default: false
        },
        getDefaultChoice: {
            type: Function,
            required: true
        },
        fillProductWithChoice: {
            type: Function,
            required: true
        },
        isProductListFromParent: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ProductGrid
    },
    data() {
        return {
            category: {},
            imageEachXElements: 6
        }
    },
    computed: {
        isImageGrid() {
            if (!this.category) {
                return false;
            }
            const categoryKeys = Object.keys(this.category);
            return !!categoryKeys.find(key => key.startsWith('gridImage') && this.category[key]);
        }
    },
    mounted() {
       this.category = window.category || window.menu?.find(category => category.id === this.categoryid)
    }
}
</script>

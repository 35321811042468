export default class Price {

  static format(price) {
    const priceWithDecimal = price / 100;
    let totalDecimal = 2;
    if (window.globalConfig?.hidePriceDecimals) {
      totalDecimal = 0;
    }
    const priceString = priceWithDecimal.toFixed(totalDecimal);
    return priceString.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
  }
}
<template>
    <div :class="`product-item-photo${isFromList ? ' col-sm-2' : ''}`">
        <a :href="product.url">
            <img class="img-fluid product-image"
                 :src="productImage"
                 :alt="product.name">
        </a>
    </div>
</template>

<script>

export default {
    name: 'ProductListImage',
    props: {
        product: {
            type: Object,
            required: true
        },
        isFromList: {
            type: Boolean,
            default: false
        },
        isProductListFromParent: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        productImage() {
            const featuredImage = this.product?.images?.find(image => image.type === 'featuredImage');
            return !this.isProductListFromParent && featuredImage ? featuredImage.src : this.product?.images?.find(image => image.type === 'main').src;
        }
    }
}
</script>
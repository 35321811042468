export default class ExtraSellService {
    static fillProductsInProductList(extraSells, products) {
        for (const extra of extraSells) {
            const alreadyInExtraProducts = products.find(item => item.productId === extra.id);

            if (!alreadyInExtraProducts) {
                products.push(extra);
            }
        }
        return products;
    }

    static getExtraProducts(enrichedProduct, extrasells, cartItems, extrasellItems, mode = '') {
        let enrichedChoice = enrichedProduct.choices.find(choice => choice.default === true);
        if (!enrichedChoice) {
            enrichedChoice = enrichedProduct.choices.find(choice => choice.show === true);
        }

        const extrasellToEnrich = extrasells.find((item) => item.productId === enrichedProduct.id);
        const productAlreadyInCart = cartItems.find(item => item.id === enrichedProduct.id);

        if(extrasellToEnrich) {

            if(productAlreadyInCart){
                extrasellToEnrich.alreadyInCart = true;
            } else if (enrichedChoice) {
                extrasellToEnrich.alreadyInCart = false;
            }

            if(!extrasellToEnrich.choice) {
              Object.assign(extrasellToEnrich, enrichedProduct);
              Object.assign(extrasellToEnrich, {choice: enrichedChoice});
            }
        }

        const products = this.fillProductsInProductList(extrasells, extrasellItems);

        return products.filter((item) => item.choice && (!item.alreadyInCart || mode === 'products-list-direct-buy'));
    }
}
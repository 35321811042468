<template>
    <div class="payment-form">
        <ul v-if="!isEligibleToOneClick">
            <li v-if="isBankCardAvailable" :class="paymentMethodsOptions?.bank_card?.cssClass+ ' bankcard'">
                <input type="radio" id="bank_card" :value="'bank_card'" v-model="selectedPaymentMethod"/>
                <label for="bank_card">{{ $t('labels.bankCard') }}</label>
            </li>
            <li v-if="isSepaAvailable" :class="paymentMethodsOptions?.sepa?.cssClass+ ' sepa'">
                <input type="radio" id="sepa" :value="'sepa'" v-model="selectedPaymentMethod"/>
                <label for="sepa">{{ $t('labels.sepa') }}</label>
            </li>
        </ul>

        <div v-if="!isEligibleToOneClick && hasPaymentError" class="alert alert-warning">
            {{ $t("messages.paymentError") }}
        </div>
        <OneClick v-if="isEligibleToOneClick" class="btn-1c"/>
        <iframe
            v-else-if="(isFormValid || palyanceIframeAlwaysOn) && iframeUrl"
            ref="paymentIFrame"
            v-show="!loading"
            :src="iframeUrl"
            allowtransparency="true"
            class="vanguard-payment-iframe"
            :style="`height: ${iframeHeightPalyance}px`"
            @load="initPaymentIFrame"
         ></iframe>
        <div v-if="isFormValid && loading && !isEligibleToOneClick" class="loading"></div>
    </div>
</template>

<script>
import {useDraftStore} from '@/store/draft.js';
import {useCartStore} from '@/store/cart.js';
import {useAspspStore} from "@/store/aspsp";
import {useTrackingStore} from "@/store/tracking";
import {constants} from '@/constants';
import OneClick from '@/components/checkout/OneClick';
import Palyance from '@/services/palyance';
import OneClickService from '@/services/payment/oneClick';
import Logger from "@/services/logger";
import Navigation from "@/services/navigation";

export default {
    name: 'CheckoutPaymentForm',
    components: {OneClick},
    props: {
        paymentMethodsOptions: {
            type: Object,
            required: false,
            default: {}
        }
    },
    data() {
        return {
            cart: null,
            isFormValid: false,
            selectedPaymentMethod: null,
            iframeHeightPalyance: 450,
            iframeUrl: null,
            paymentFormLoading: false,
            hasPaymentError: false,
            isBankCardAvailable: true,
            isSepaAvailable: true,
            palyanceIframeAlwaysOn: false
        }
    },
    computed: {
        loading() {
            return useDraftStore().isSendingDraftOrder || this.paymentFormLoading;
        },
        draftIsValid() {
          return useDraftStore().isFormValid;
        },
        lastCartUpdate() {
            return useCartStore().lastUpdate;
        },
        order() {
            return useDraftStore().order;
        },
        isEligibleToOneClick() {
          return OneClickService.isEligibleToOneClick();
        }
    },
    watch: {
        lastCartUpdate: {
            handler(newValue, oldValue) {
                if (!window?.globalConfig?.domainId) {
                    return;
                }
                this.cart = this.getCart();
            },
        },
        cart: {
            handler(newValue, oldValue) {
                if (!window?.globalConfig?.domainId) {
                    return;
                }
                this.formIsValid = this.checkFormValidity();
            },
            deep: true
        },
        draftIsValid: {
            handler(newValue, oldValue) {
                if (!window?.globalConfig?.domainId) {
                    return;
                }

                this.isFormValid = this.checkFormValidity();
            }
        },
        selectedPaymentMethod: function (newVal, oldVal) {
            useDraftStore().selectPaymentMethod(newVal);
            this.setAspspData(newVal);
            this.updateIframeUrl();
        },
        isFormValid: function (newVal, oldVal) {
            if (newVal) {
                this.updateIframeUrl();
            }
        },
      order: {
        handler() {
          this.updateShippingAndBillingInformations();
          this.updateIframeUrl();
        },
        deep: true
      },
    },
    mounted() {
        // TODO : utiliser la method PaymentMethodService.getDefaultPaymentMethod()

        this.cart = this.getCart();
        this.isFormValid = this.checkFormValidity();
        this.setPaymentMethodAvailability();

        if (!this.isSepaAvailable && !this.isBankCardAvailable) {
            console.error('No payment method available');
            return;
        }

        if (useDraftStore().selectedPaymentMethod) {
            this.selectedPaymentMethod = useDraftStore().selectedPaymentMethod;
        }

        if (this.isSepaAvailable) {
            this.selectedPaymentMethod = constants.PAYMENT_METHOD_SEPA;
        } else if (this.isBankCardAvailable) {
            this.selectedPaymentMethod = constants.PAYMENT_METHOD_BANK_CARD;
        }

        this.getAspspEligibilityData();

        this.palyanceIframeAlwaysOn = window.globalConfig.palyanceIframeAlwaysOn || false;
        if (this.palyanceIframeAlwaysOn) {
          this.updateIframeUrl();
        }
    },
    methods: {
        getCart() {
            return useCartStore().cart;
        },
        checkFormValidity() {
          return this.getCart() && this.getCart().items.length && this.draftIsValid
        },
        setPaymentMethodAvailability() {
            // if config not exists : display both payment methods or config exists and bank_card is available
            this.isBankCardAvailable = !window.globalConfig.paymentMethodsAvailable ||
                !window.globalConfig.paymentMethodsAvailable.includes(',') ||
                window.globalConfig.paymentMethodsAvailable.split(',').includes('bank_card');

            // if config not exists : display both payment methods or config exists and bank_card is available
            this.isSepaAvailable = !window.globalConfig.paymentMethodsAvailable ||
                !window.globalConfig.paymentMethodsAvailable.includes(',') ||
                window.globalConfig.paymentMethodsAvailable.split(',').includes('sepa');
        },
        updateIframeUrl() {
            if (!window.globalConfig || (!this.isFormValid && !this.palyanceIframeAlwaysOn)) {
                return null;
            }

            let newIframeUrl;

            if (this.selectedPaymentMethod === constants.PAYMENT_METHOD_SEPA) {
                newIframeUrl = `${process.env.VUE_APP_PALYANCE_APP_FRONT_URL}/sepa/capture/?${this.getPalyanceV2QueryParams()}`;
            } else {
                newIframeUrl = `${process.env.VUE_APP_PALYANCE_APP_FRONT_URL}/bankcard/capture/?${this.getPalyanceV2QueryParams()}`;
            }

            if (newIframeUrl !== this.iframeUrl) {
                this.paymentFormLoading = true;
                this.iframeUrl = newIframeUrl;
             } else {
                console.log("Iframe URL did not change");
            }
         },
        getAspspEligibilityData() {
            let sfId = null, walletIdentifier = null;
            const regexIdentifier = /^[a-zA-Z0-9]*$/;

            if (useTrackingStore().urlParams.sfId) {
                sfId = useTrackingStore().urlParams.sfId;

                if (sfId && !regexIdentifier.test(sfId)) {
                    sfId = sfId.replace(/[^a-z0-9.]+/ig, '');
                }
            }

            if (useTrackingStore().urlParams.walletId && regexIdentifier.test(useTrackingStore().urlParams.walletId)) {
                useDraftStore().prefillLoading(true);
                walletIdentifier = useTrackingStore().urlParams.walletId;

                Palyance.getAspspEligibility(
                    walletIdentifier,
                    this.selectedPaymentMethod, window.globalConfig.affiliateId
                ).then(data => {
                    useDraftStore().prefillLoading(false);

                    if (JSON.stringify(data) === '{}') {
                        return;
                    }

                  const paymentData = data[this.selectedPaymentMethod];
                  const shippingCountryPreffiled = paymentData.content.SHIPPINGCOUNTRY__C || paymentData.content.BILLINGCOUNTRY__C;

                  // Check if the country is excluded
                  if (window.globalConfig.countriesExcluded && window.globalConfig.countriesExcluded.includes(shippingCountryPreffiled)) {
                    console.log(`[vanguard] Country ${shippingCountryPreffiled} are excluded so the prefilled data will not be used`);
                    useAspspStore().eligible(false);
                    return;
                  }
                    useAspspStore().eligible(true);

                    /*  If SEPA is not aspsp eligible, but BANK_CARD is eligible and available => BANK_CARD
                        If any other situation => SEPA :
                            - aspsp SEPA eligible OR
                            - not aspsp BANK_CARD eligible OR
                            - no payment method eligible OR
                            - BANK_CARD not available
                     */
                    if (!data.sepa.paymentIdentifier && data.bank_card.paymentIdentifier && this.isBankCardAvailable) {
                        this.selectedPaymentMethod = constants.PAYMENT_METHOD_BANK_CARD;
                    } else {
                        this.selectedPaymentMethod = constants.PAYMENT_METHOD_SEPA;
                    }

                    if(data.bank_card.paymentIdentifier) {
                      useAspspStore().updateAspspEligibleBankCard(true)
                    }
                    if(data.sepa.paymentIdentifier) {
                      useAspspStore().updateAspspEligibleSepa(true)
                    }

                    useAspspStore().updateAspspPaymentInformations(data);

                    this.setAspspData(this.selectedPaymentMethod)
                    this.setAspspContent(this.selectedPaymentMethod)

                    this.updateIframeUrl()
                }).catch(error => {
                    console.error(error);
                    Logger.error('Error on getAspspEligibility call', error);
                });
            }
        },
      formatFullAddress(data) {

        const billingStreet = data.BILLINGSTREET3__C;
        const billingZipCode = data.BILLINGZIPCODE__C;
        const billingCity = data.BILLINGCITY__C;

        const shippingStreet = data.SHIPPINGSTREET3__C || data.BILLINGSTREET3__C;
        const shippingZipCode = data.SHIPPINGZIPCODE__C || data.BILLINGZIPCODE__C;
        const shippingCity = data.SHIPPINGCITY__C || data.BILLINGCITY__C;
        const billingAddress = `${billingStreet}, ${billingZipCode} ${billingCity}`;
        const shippingAddress = `${shippingStreet}, ${shippingZipCode} ${shippingCity}`;

        if (billingAddress !== shippingAddress) {
          useDraftStore().updateIsDistinctAdress(true);
          return {
            billingAddress,
            shippingAddress
          };
        } else {
          useDraftStore().updateIsDistinctAdress(false);
          return billingAddress;
        }
      },
        mapLanguageToOgoneFormat(lang) {
        const langMap = {
          'fr': 'fr_FR',
          'en': 'en_US',
          'es': 'es_ES',
          'de': 'de_DE',
          'it': 'it_IT'
        };

          return langMap[lang] || 'fr_FR';
        },
        getPalyanceV2QueryParams() {
            const exceptionUrl = `${window.location.origin}${window.globalConfig.errorPage}`;
            const waitingUrl = `${window.location.origin}${window.globalConfig.waitingPage}`;
            const waitingDoubleOptinUrl = `${window.location.origin}${window.globalConfig.waitingDoubleOptinPage}`;
            this.updateShippingAndBillingInformations();
            const order = this.order;
            const shippingInformations = {
              type: 'shipping',
              firstName: order.shippingFirstName ,
              lastName: order.shippingLastName,
              address: order.shippingStreet1 || '',
              address2: order.shippingStreet2 || '',
              address3: order.shippingStreet3 || '',
              address4: order.shippingStreet4 || '',
              phone: order.shippingPhoneNumber || '',
              zipCode: order.shippingZipCode,
              city: order.shippingCity,
              country: order.shippingCountry,
            };

            const paramPlus = this.buildParamPlus();

            const payload = {
                amount: this.cart.total,
                totalAmount: this.cart.total,
                merchantIdentifier: window.globalConfig.affiliateId,
                merchantName: window.globalConfig.brandName,
                doubleOptin: useAspspStore().isDoubleOptin ? 1 : 0,
                defaultLocale: this.mapLanguageToOgoneFormat(window.globalConfig.lang),
                lang: window.globalConfig.lang,
                orderId: this.order.externalOrderId,
                billingInformations: {
                    firstName: order.billingFirstName,
                    lastName: order.billingLastName,
                    address: order.billingStreet1 || '',
                    address2: order.billingStreet2 || '',
                    address3: order.billingStreet3 || '',
                    address4: order.billingStreet4 || '',
                    phone: order.billingPhoneNumber || '',
                    zipCode: order.billingZipCode,
                    city: order.billingCity,
                    country: order.billingCountry,
                    email: order.billingEmail?.toLowerCase()
                },
                shippingInformations: shippingInformations,
                technicalContract: useDraftStore().areCgvApproved ? 1 : 0,
                aspspContract: useDraftStore().areCgsApproved ? 1 : 0,
                sourceUrl: encodeURIComponent(document.location.href),
                formIsValid: useDraftStore().isFormValid,
                productName: 'shop',
                browserData: this.presentBrowserData(),
                waitingUrl: waitingUrl,
                exceptionUrl: exceptionUrl,
                palyanceIframeAlwaysOn: this.palyanceIframeAlwaysOn
            };

            if ((this.selectedPaymentMethod === constants.PAYMENT_METHOD_SEPA && useAspspStore().isAspspEligibleSepa) || (this.selectedPaymentMethod === constants.PAYMENT_METHOD_BANK_CARD && useAspspStore().isAspspEligibleBankCard) ) {
                const regex = /^[a-zA-Z0-9]*$/;
                const walletId = useTrackingStore().urlParams.walletIdentifier || useTrackingStore().urlParams.walletId;
                const queryParams = Navigation.getQueryParams();
                const paymentId = queryParams.get('paymentId') || useAspspStore().paymentIdentifier; // paymentId is used when recapture

                payload.walletIdentifier = regex.test(walletId) ? walletId : null;
                payload.paymentInformationIdentifier = paymentId;
                payload.mandateIdentifier = useAspspStore().mandateIdentifier;
                payload.paymentInformationObfuscated = useAspspStore().paymentAccountIdentifier;
                payload.pciToken = useAspspStore().pciToken;
                payload.holderName = useAspspStore().holderName;

                if (this.selectedPaymentMethod === constants.PAYMENT_METHOD_SEPA) {
                    payload.waitingUrl = waitingDoubleOptinUrl;

                    paramPlus.emailDoubleOptin = useAspspStore().email;
                    paramPlus.countdownStartTimeMs = Date.now();
                }
            }

            if (this.paymentMethodsOptions.iframeStyle) {
                payload.iframeStyle = 'orderPage';
            }

            localStorage.setItem(constants.PARAM_PLUS, JSON.stringify(paramPlus));

            const queryParams = new URLSearchParams();
            queryParams.append('json', JSON.stringify(payload));

            return queryParams.toString();
        },
        presentBrowserData() {
            if (typeof window.screen === 'undefined') {
                window.screen = {};
            }

            if (typeof window.navigator === 'undefined' || typeof window.navigator.javaEnabled !== 'function') {
                window.navigator = {
                    javaEnabled() {
                        return false;
                    }
                };
            }

            return {
                colorDepth: window.screen.colorDepth,
                javaEnabled: window.navigator.javaEnabled().toString(),
                language: window.navigator.language,
                screenHeight: window.screen.height,
                screenWidth: window.screen.width,
                timeZone: new Date().getTimezoneOffset()
            };
        },
        buildParamPlus() {
            const paramPlus = {};

            if (window.location.search.length > 0) {
                window.location.search.slice(1).split('&').forEach(pair => {
                    const row = pair.split('=');
                    paramPlus[row[0]] = row[1];
                });
            }

            paramPlus.orderId = this.order.externalOrderId;
            //paramPlus.confirmationUrl = confirmationUrl;
            //paramPlus.exceptionUrl = exceptionUrl;
            paramPlus.paymentType = this.selectedPaymentMethod;
            paramPlus.action = 'capture';

            return paramPlus;
        },
        initPaymentIFrame() {
            // Listen to IFrame messages to retrieve payment data
            window.addEventListener('message', this.iframeMessageCallback, false);

            if (!this.$refs.paymentIFrame) {
                console.error('Unable to retrieve payment IFrame DOM node');
            }

            const paymentIFrame = this.$refs.paymentIFrame.contentWindow;
            paymentIFrame.postMessage('FrameHeightPalyance', '*');

            this.paymentFormLoading = false;
        },
        iframeMessageCallback(event) {
            // Check whether message origin is Palyance origin
            if (event.origin !== process.env.VUE_APP_PALYANCE_APP_FRONT_URL) {
                console.debug('Origin does not match with Palyance origin', event);
                return;
            }

            if (event && event.data && typeof event.data.FrameHeightPalyance !== 'undefined') {
                console.debug('event.data.FrameHeightPalyance', event.data.FrameHeightPalyance);
                this.iframeHeightPalyance = event.data.FrameHeightPalyance;
                return;
            }

          if (event && event.data && typeof event.data.contactFormCheckValidation !== 'undefined') {
            // handled in contactForm component
            return;
          }

            const response = event.data;
            if (typeof response.isLoaded !== 'undefined') {
                return;
            }

            // If an error occurred, display error message then reset IFrame
            if (!response.status) {
                console.error('Palyance Payment error', response.data);
                this.hasPaymentError = true;
                return;
           }
        },
      setAspspData(paymentMethod) {
        useAspspStore().updateAspspData(paymentMethod)
      },
      setAspspContent(paymentMethod) {
        const data = useAspspStore().aspspPaymentInformations[paymentMethod];
        useAspspStore().updatePrefillData(data.content);
        useAspspStore().updateEmail(data.content.EMAIL);
        useAspspStore().updatAspspFullAdress(this.formatFullAddress(data.content));
      },
        updateShippingAndBillingInformations() {
        const isSameAddress = this.order.sameAsBilling;
        this.billingInformations = {
          type: 'billing',
          firstName: this.order.billingFirstName,
          lastName: this.order.billingLastName,
          address: this.order.billingStreet1,
          address2: this.order.billingStreet2,
          address3: this.order.billingStreet3,
          address4: this.order.billingStreet4,
          phone: this.order.billingPhoneNumber,
          zipCode: this.order.billingZipCode,
          city: this.order.billingCity,
          country: this.order.billingCountry,
          email: this.order.billingEmail
        };
        this.shippingInformations = {
          type: 'shipping',
          firstName: isSameAddress ? this.order.billingFirstName : this.order.shippingFirstName,
          lastName: isSameAddress ? this.order.billingLastName : this.order.shippingLastName,
          address: isSameAddress ? this.order.billingStreet1 : this.order.shippingStreet1,
          address2: isSameAddress ? this.order.billingStreet2 : this.order.shippingStreet2,
          address3: isSameAddress ? this.order.billingStreet3 : this.order.shippingStreet3,
          address4: isSameAddress ? this.order.billingStreet4 : this.order.shippingStreet4,
          phone: isSameAddress ? this.order.billingPhoneNumber : this.order.shippingPhoneNumber,
          zipCode: isSameAddress ? this.order.billingZipCode : this.order.shippingZipCode,
          city: isSameAddress ? this.order.billingCity : this.order.shippingCity,
          country: isSameAddress ? this.order.billingCountry : this.order.shippingCountry
        };
      },
    },
}
</script>